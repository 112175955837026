import { ChangeDetectionStrategy, Component } from '@angular/core';

import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { environment } from '../../../../environments/environment';
import { DataService } from '../../../services/shared-data.service';
import { CallMeDialogComponent } from '../../modal/call-me-dialog/call-me-dialog.component';
import { GetQuoteDialogComponent } from '../../modal/get-quote-dialog/get-quote-dialog.component';

@Component({
  selector: 'app-free-quote',
  templateUrl: './free-quote.component.html',
  styleUrls: ['./free-quote.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FreeQuoteComponent {
  public cdnPath: string;

  constructor(
    private modalService: NgbModal,
    private dataService: DataService,
  ) {
    this.cdnPath = environment.cdnPath;
  }

  public openCallBackDialog(): void {
    this.dataService.sendData({ type: 'showModal', data: true });
    this.modalService.open(CallMeDialogComponent, { centered: true, size: 'lg' });
  }

  public openGetQuoteDialog(): void {
    this.dataService.sendData({ type: 'showModal', data: true });
    this.modalService.open(GetQuoteDialogComponent, { fullscreen: true });
  }
}
