import { Component } from '@angular/core';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'app-pricing-how-it-works',
  templateUrl: './pricing-how-it-works.component.html',
  styleUrls: ['./pricing-how-it-works.component.scss'],
})
export class PricingHowItWorksComponent {
  public cdnPath: string;

  constructor() {
    this.cdnPath = environment.cdnPath;
  }
}
