import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import { environment } from '../../../../environments/environment';

@Component({
  selector: 'app-news-letter-success-dialog',
  templateUrl: './news-letter-success-dialog.component.html',
  styleUrls: ['./news-letter-success-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NewsLetterSuccessDialogComponent {
  public cdnPath: string;

  constructor(
    private dialogRef: MatDialogRef<NewsLetterSuccessDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { isCallBack: boolean },
  ) {
    this.cdnPath = environment.cdnPath;
  }

  public onCloseDialog(): void {
    this.dialogRef.close();
  }
}
