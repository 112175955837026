export class Flight {
  departDate: any;
  from: string;
  to: string;
  iataFrom: string;
  iataTo: string;
  cityNameFrom: string;
  cityNameTo: string;
  regionFrom: string;
  regionTo: string;
}
