<!-- todo change markup of dialog -->
<div id="callBack">
  <div class="modal-body">
    <button
      (click)="activeModal.dismiss()"
      aria-label="Close"
      class="btn-close"
      data-bs-dismiss="modal"
      type="button"></button>
    <div class="row align-items-center">
      <div class="d-none d-sm-block col-lg-6">
        <img
          [lazyLoad]="cdnPath + '/assets/images/content/img/modal-call.png'"
          alt="Services"
          aria-hidden="true"
          class="select-box__icon"
          style="width: 100%" />
      </div>
      <div class="col-sm-12 col-lg-6">
        <span class="modal-title" id="callBackLabel">Request a call back</span>
        <p>A travel expert will call you ASAP!</p>

        <form (ngSubmit)="callMe()" [formGroup]="callMeForm" class="p-4" #callForm="ngForm">
          <div class="row mb-3">
            <label class="visually-hidden" for="autoSizingInputName">Name</label>
            <input
              class="form-control"
              formControlName="firstName"
              id="autoSizingInputName"
              placeholder="Name"
              type="text"
              [(ngModel)]="callMeData.firstName"
              [ngClass]="{
                'has-error': callMeForm.get('firstName')!.invalid && callForm.submitted,
              }" />
          </div>

          <div class="row mb-3">
            <label class="visually-hidden" for="autoSizingInputPhone">Phone</label>
            <input
              class="form-control"
              formControlName="phone"
              id="autoSizingInputPhone"
              placeholder="Phone"
              type="text"
              [textMask]="{ mask: phoneMask }"
              [(ngModel)]="callMeData.phone"
              [ngClass]="{ 'has-error': callMeForm.get('phone')!.invalid && callForm.submitted }" />
          </div>

          <!--                    <div class="row mb-3">-->
          <!--                        <label class="visually-hidden" for="autoSizingInputPhone">Phone</label>-->
          <!--                        <input class="form-control" formControlName="email" id="autoSizingInputEmail"-->
          <!--                               placeholder="Email" type="email" [(ngModel)]="callMeData.email"-->
          <!--                               [ngClass]="{'has-error': callMeForm.get('email')!.invalid && callForm.submitted}">-->
          <!--                    </div>-->

          <div class="row mb-3">
            <button class="btn btn-primary" type="submit">Call me back</button>
          </div>

          <small>We guarantee 100% privacy.</small>
        </form>
      </div>
    </div>
  </div>
</div>
