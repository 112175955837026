import { Injectable } from '@angular/core';
import { DefaultUrlSerializer, UrlTree } from '@angular/router';

@Injectable()
export class OrderUrlSerializer extends DefaultUrlSerializer {
  override parse(urlAddress: string): UrlTree {
    let url: string = urlAddress;
    if (url.indexOf('business-class-flights-to-') >= 0) {
      url = url.replace('business-class-flights-to-', 'business-class-flights-to/');
    } else if (url.indexOf('business-class-airlines-') >= 0) {
      url = url.replace('business-class-airlines-', 'business-class-airlines/');
    } else if (url.indexOf('business-class-') >= 0) {
      url = url.replace('business-class-', 'business-class/');
    } else if (url.indexOf('first-class-flights-to-') >= 0) {
      url = url.replace('first-class-flights-to-', 'first-class-flights-to/');
    } else if (url.indexOf('first-class-airlines-') >= 0) {
      url = url.replace('first-class-airlines-', 'first-class-airlines/');
    } else if (url.indexOf('first-class') >= 0) {
      url = url.replace('first-class-', 'first-class/');
    }
    return super.parse(url);
  }

  override serialize(tree: UrlTree): string {
    let url = super.serialize(tree);
    if (url.indexOf('business-class-flights-to/') >= 0) {
      url = url.replace('business-class-flights-to/', 'business-class-flights-to-');
    } else if (url.indexOf('business-class-airlines/') >= 0) {
      url = url.replace('business-class-airlines/', 'business-class-airlines-');
    } else if (url.indexOf('business-class/') >= 0) {
      url = url.replace('business-class/', 'business-class-');
    } else if (url.indexOf('first-class-flights-to/') >= 0) {
      url = url.replace('first-class-flights-to/', 'first-class-flights-to-');
    } else if (url.indexOf('first-class-airlines/') >= 0) {
      url = url.replace('first-class-airlines/', 'first-class-airlines-');
    } else if (url.indexOf('first-class/') >= 0) {
      url = url.replace('first-class/', 'first-class-');
    }
    return url;
  }
}
