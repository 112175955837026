import { Component, Input } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';

import { DataService } from '../../../services/shared-data.service';
import { GetQuoteDialogComponent } from '../../modal/get-quote-dialog/get-quote-dialog.component';

@Component({
  selector: 'app-country-deal',
  templateUrl: './country-deal.component.html',
  styleUrls: ['./country-deal.component.scss'],
})
export class CountryDealComponent {
  @Input('content') public text: string;

  constructor(
    private modalService: NgbModal,
    private dataService: DataService,
  ) {}

  public getQuote(event): void {
    event.preventDefault();
    if (event.target.classList.contains('get-a-quote')) {
      this.dataService.sendData({ type: 'showModal', data: true });
      const modalRef: NgbModalRef = this.modalService.open(GetQuoteDialogComponent, {
        fullscreen: true,
      });
      modalRef.componentInstance.setFlightsToModal = {
        flightFrom: event.target.getAttribute('flyingFrom'),
        flightTo: event.target.getAttribute('flyingTo'),
      };
      modalRef.componentInstance.flightPrice = event.target
        .closest('tr')
        .querySelector('.total-price span')
        .innerHTML.replace(/\D/g, '');
    }
  }
}
