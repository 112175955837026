<section id="info">
  <div class="container col-xl-12 px-4">
    <div class="row align-items-center">
      <div class="d-none d-md-block d-sm-none col-md-3 col-lg-2 text-left text-lg-start">
        <img
          [defaultImage]="cdnPath + '/assets/images/content/img/call-background.webp'"
          [lazyLoad]="cdnPath + '/assets/images/content/img/call.png'"
          alt="Services"
          aria-hidden="true"
          class="select-box__icon" />
      </div>
      <div class="col-md-8 col-lg-10 col-xl-5 pt-3 text-left text-lg-start">
        <span class="fw-bold lh-1 mb-3">Speak with a travel expert</span>
        <p class="col-lg-10">A travel expert will call you back ASAP!</p>
        <form [formGroup]="callBackForm" class="row g-3">
          <div class="col-6">
            <label class="visually-hidden" for="staticPhone2">Phone</label>
            <input
              [class.error]="callBackForm.get('phone')?.invalid && submittedPhoneForm"
              [textMask]="{ mask: phoneMask }"
              [attr.disabled]="phoneFormSending ? '' : null"
              class="form-control-plaintext"
              formControlName="phone"
              id="staticPhone2"
              placeholder="Phone"
              type="text" />
          </div>
          <div class="col-auto">
            <button
              (click)="callMe()"
              [attr.disabled]="phoneFormSending ? '' : null"
              class="btn btn-primary mb-3"
              type="submit">
              {{ phoneFormSending === false ? 'Call Me Back' : 'Loading...' }}
            </button>
          </div>
        </form>
      </div>

      <div
        *ngIf="alreadySignedUp === false"
        class="col-lg-5 d-none d-xl-block pt-3 text-right text-lg-start">
        <span class="fw-bold lh-1 mb-3">Get deals in your inbox</span>
        <p class="col-lg-10">Exclusive flight deals, specials and promotions!</p>
        <form [formGroup]="hotDealsForm" class="row g-3">
          <div class="col-6">
            <label class="visually-hidden" for="staticEmail2">Email</label>
            <input
              [(ngModel)]="email"
              [attr.disabled]="formSending ? '' : null"
              [class.error]="hotDealsForm.get('email').invalid && !formValid"
              formControlName="email"
              class="form-control-plaintext"
              id="staticEmail2"
              placeholder="Email"
              type="text" />
          </div>
          <div class="col-auto">
            <button
              (click)="sendHotDeal()"
              [attr.disabled]="formSending ? '' : null"
              class="btn btn-primary mb-3"
              type="submit">
              {{ formSending === false ? 'Send Me Deals' : 'Loading...' }}
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</section>
