import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment';
import { DurationInputArg1, DurationInputArg2 } from 'moment';

export const formatDate = (date: NgbDateStruct): string => {
  return `${date.month}/${date.day}/${date.year}`;
};

export const formatDateString = (date: string, format: string) => {
  return moment(date).format(format);
};

export const isEmptyDate = (date: NgbDateStruct): boolean => {
  return date
    ? Number.isNaN(date.day) || Number.isNaN(date.month) || Number.isNaN(date.year)
    : true;
};

export const prepareDate = (
  date: Date | string,
  amount?: DurationInputArg1,
  unit?: DurationInputArg2,
): NgbDateStruct => {
  const newDate = amount && unit ? moment(date).add(amount, unit).toDate() : moment(date).toDate();
  return {
    day: newDate.getDate(),
    month: newDate.getMonth() === 12 ? 1 : newDate.getMonth() + 1,
    year: newDate.getFullYear(),
  };
};
