<div class="contact-us-modal animated fadeIn">
  <div class="modal show" tabindex="-1" role="dialog" aria-hidden="true" style="display: block">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h2 class="modal-title">We love feedback!</h2>
          <button
            type="button"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
            (click)="onCloseDialog()">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="contact-thank-text" [ngClass]="{ 'contact-failed': !success }">
          <img
            *ngIf="success"
            [lazyLoad]="cdnPath + '/assets/images/content/icons/checkmark_circle.svg'" />
          <h3 class="contact-us-response">{{ text }}</h3>
        </div>
      </div>
    </div>
  </div>
</div>
