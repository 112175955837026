<div mat-dialog-content>
  <button
    type="button"
    class="close"
    data-dismiss="modal"
    aria-label="Close"
    (click)="onCloseDialog()">
    <span aria-hidden="true">&times;</span>
  </button>
  <img [lazyLoad]="cdnPath + '/assets/images/content/icons/checkmark_circle.svg'" />
  <h2>{{ data.isCallBack ? 'We received your request.' : 'You’re all set!' }}</h2>
</div>
