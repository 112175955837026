import { Injectable, OnDestroy } from '@angular/core';

export interface ToastInfo {
  classname: string;
  body: string;
  delay?: number;
}

@Injectable({
  providedIn: 'root',
})
export class ToastService implements OnDestroy {
  public toasts: Array<ToastInfo> = [];

  constructor() {}

  public showWarning(body: string, delay?: number): void {
    this.show(body, 'warning', delay);
  }

  public showSuccess(body: string, delay?: number): void {
    this.show(body, 'success', delay);
  }

  public remove(toast: ToastInfo): void {
    this.toasts = this.toasts.filter((t: ToastInfo): boolean => t !== toast);
  }

  private clear(): void {
    this.toasts.splice(0, this.toasts.length);
  }

  private show(body: string, classname: string, delay?: number): void {
    this.toasts.unshift({ body, classname, delay });
  }

  public ngOnDestroy(): void {
    this.clear();
  }
}
