import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { environment } from '../../../../environments/environment';
import { ContactData } from '../../../model/contact-data';
import { QueryFlightParams } from '../../../model/query-flight-params';
import { RestResponse } from '../../../model/rest-response';

@Injectable()
export class SearchboxService {
  constructor(private http: HttpClient) {}

  getLocations(data: string): Observable<any> {
    const params = new HttpParams().set('term', data);
    return this.http.get(`${environment.serverUrl}/cities/get-by-query`, { params });
  }

  submitSearch(queryFlightParams: QueryFlightParams): Observable<RestResponse> {
    return this.http.post<RestResponse>(
      `${environment.serverUrl}/flight/query-flight`,
      queryFlightParams,
    );
  }

  sendRequest(contactData: ContactData): Observable<RestResponse> {
    return this.http.post<RestResponse>(
      `${environment.serverUrl}/contact-info/get-quote`,
      contactData,
    );
  }

  getInitContactData(): Observable<RestResponse> {
    return this.http.get<RestResponse>(`${environment.serverUrl}/contact-info/get-info`);
  }

  getInitQueryFlightParams(): Observable<QueryFlightParams> {
    return this.http.get<QueryFlightParams>(`${environment.serverUrl}/flight/get-search-params`);
  }
}
