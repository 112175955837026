import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatDialogModule } from '@angular/material/dialog';
import { BrowserModule } from '@angular/platform-browser';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgbDatepickerModule, NgbModalModule } from '@ng-bootstrap/ng-bootstrap';
import { TextMaskModule } from 'angular2-text-mask';
import { LazyLoadImageModule } from 'ng-lazyload-image';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { MainInterceptor } from './interceptors/main.interceptor';
import { FooterComponent } from './layout/common/footer/footer.component';
import { HeaderComponent } from './layout/common/header/header.component';
import { HomeComponent } from './layout/pages/home/home.component';
import { ToastContainerComponent } from './layout/templates/toast-container/toast-container.component';
import { LocalstorageService } from './services/local-storage.service';
import { DataService } from './services/shared-data.service';
import { TagService } from './services/tag.service';
import { SharedModule } from '../shared/shared.module';
import { CookieStorage } from '../storage/browser.storage';
import { AppStorage } from '../storage/universal.inject';

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    FooterComponent,
    HeaderComponent,
    ToastContainerComponent,
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    AppRoutingModule,
    LazyLoadImageModule,
    FormsModule,
    ReactiveFormsModule,
    NgbModalModule,
    NgbDatepickerModule,
    HttpClientModule,
    MatDialogModule,
    SharedModule,
    TextMaskModule,
    CommonModule,
    BrowserAnimationsModule,
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: MainInterceptor, multi: true },
    { provide: 'isBrowser', useValue: true },
    { provide: AppStorage, useClass: CookieStorage },
    DataService,
    TagService,
    LocalstorageService,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
