import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';

@Injectable()
export class CookieStorage /* implements Storage TODO: remove after all updates */ {
  [index: number]: string;
  [key: string]: any;
  length: number;

  constructor(private cookieService: CookieService) {}

  clear(): void {
    this.cookieService.deleteAll();
  }

  getItem(key: string): string {
    const result = this.cookieService.get(key);

    return result;
  }

  //  TODO: remove after all updates
  // key(index: number): string {
  //   const result = this.cookieService.getAll()[index];
  //
  //   return result;
  // }

  removeItem(key: string): void {
    this.cookieService.delete(key);
  }

  setItem(key: string, data: string): void {
    this.cookieService.set(key, data);
  }
}
