<ng-container *ngIf="extendedListDeals && (isContinent || isCountry || isCity)">
  <ng-container *ngIf="deal === DealsListEnum.Europe" [ngTemplateOutlet]="europe"></ng-container>
  <ng-container *ngIf="deal === DealsListEnum.Asia" [ngTemplateOutlet]="asia"></ng-container>
  <ng-container
    *ngIf="deal === DealsListEnum.Africa"
    [ngTemplateOutlet]="africaMiddleEast"></ng-container>
  <ng-container
    *ngIf="deal === DealsListEnum.MiddleEast"
    [ngTemplateOutlet]="africaMiddleEast"></ng-container>
  <ng-container *ngIf="deal === DealsListEnum.Oceania" [ngTemplateOutlet]="ocenia"></ng-container>
  <app-country-deal *ngIf="isCountry" [content]="text"></app-country-deal>
  <app-city-deal *ngIf="isCity" [content]="text"></app-city-deal>
</ng-container>

<ng-container *ngIf="!extendedListDeals">
  <section id="deals">
    <div class="container col-xl-12 px-4 py-5">
      <div class="row align-items-center">
        <h1 class="fw-bold lh-1 mb-3">Cheap Business Class Tickets</h1>

        <div class="deals-block">
          <div class="row g-4 py-3 row-cols-1 row-cols-sm-2 row-cols-md-2 row-cols-lg-4">
            <div class="feature col">
              <div class="">
                <a [routerLink]="['business-class/', DealsListEnum.Europe]">
                  <img
                    [lazyLoad]="
                      cdnPath +
                      '/assets/images/content/img/continent/business-class-flights-rome.png'
                    "
                    alt="Rome"
                    class="d-none d-sm-none d-md-none d-lg-block" />
                  <img
                    [lazyLoad]="
                      cdnPath +
                      '/assets/images/content/img/continent/business-class-flights-rome_md.png'
                    "
                    alt="Rome"
                    class="d-lg-none d-xl-none d-xxl-none" />
                </a>
              </div>
              <p>Business Class</p>
              <h2>to Europe</h2>

              <a class="icon-link" [routerLink]="['business-class/', DealsListEnum.Europe]">
                from <b>$2,120</b>
                <img
                  [lazyLoad]="cdnPath + '/assets/images/content/icons/arrow_forward.svg'"
                  alt="Arrow Icon"
                  class="bi" />
              </a>
            </div>
            <div class="feature col">
              <div class="">
                <a [routerLink]="['business-class/', DealsListEnum.Asia]">
                  <img
                    [lazyLoad]="
                      cdnPath +
                      '/assets/images/content/img/continent/business-class-flights-bangkok.png'
                    "
                    alt="Bangkok"
                    class="d-none d-sm-none d-md-none d-lg-block" />
                  <img
                    [lazyLoad]="
                      cdnPath +
                      '/assets/images/content/img/continent/business-class-flights-bangkok_md.png'
                    "
                    alt="Bangkok"
                    class="d-lg-none d-xl-none d-xxl-none" />
                </a>
              </div>
              <p>Business Class</p>
              <h2>to Asia</h2>

              <a class="icon-link" [routerLink]="['business-class/', DealsListEnum.Asia]">
                from <b>$2,721</b>
                <img
                  [lazyLoad]="cdnPath + '/assets/images/content/icons/arrow_forward.svg'"
                  alt="Arrow Icon"
                  class="bi" />
              </a>
            </div>
            <div class="feature col">
              <div class="">
                <a [routerLink]="['business-class/', DealsListEnum.Africa]">
                  <img
                    [lazyLoad]="
                      cdnPath +
                      '/assets/images/content/img/continent/business-class-flights-dubai.png'
                    "
                    alt="Dubai"
                    class="d-none d-sm-none d-md-none d-lg-block" />
                  <img
                    [lazyLoad]="
                      cdnPath +
                      '/assets/images/content/img/continent/business-class-flights-dubai_md.png'
                    "
                    alt="Dubai"
                    class="d-lg-none d-xl-none d-xxl-none" />
                </a>
              </div>
              <p>Business Class</p>
              <h2>to Africa/Middle East</h2>

              <a class="icon-link" [routerLink]="['business-class/', DealsListEnum.Africa]">
                from <b>$2,830</b>
                <img
                  [lazyLoad]="cdnPath + '/assets/images/content/icons/arrow_forward.svg'"
                  alt="Arrow Icon"
                  class="bi" />
              </a>
            </div>
            <div class="feature col">
              <div class="">
                <a [routerLink]="['business-class/', DealsListEnum.Oceania]">
                  <img
                    [lazyLoad]="
                      cdnPath +
                      '/assets/images/content/img/continent/business-class-flights-sydney.png'
                    "
                    alt="Sydney"
                    class="d-none d-sm-none d-md-none d-lg-block" />
                  <img
                    [lazyLoad]="
                      cdnPath +
                      '/assets/images/content/img/continent/business-class-flights-sydney_md.png'
                    "
                    alt="Sydney"
                    class="d-lg-none d-xl-none d-xxl-none" />
                </a>
              </div>
              <p>Business Class</p>
              <h2>to Oceania</h2>

              <a class="icon-link" [routerLink]="['business-class/', DealsListEnum.Oceania]">
                from <b>$3,018</b>
                <img
                  [lazyLoad]="cdnPath + '/assets/images/content/icons/arrow_forward.svg'"
                  alt="Arrow Icon"
                  class="bi" />
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</ng-container>

<ng-template #europe>
  <section id="cities-header">
    <div class="container col-xl-12 px-4 py-5">
      <div class="row align-items-center">
        <h1 class="fw-bold lh-1 mb-3">Cheap Business Class Tickets To Europe</h1>
      </div>
    </div>
  </section>

  <section id="deals">
    <div class="container col-xl-12">
      <div class="row g-4 pb-5 row-cols-1 row-cols-md-2 row-cols-lg-3">
        <div class="feature col pb-4 d-flex align-items-center">
          <a [routerLink]="['/business-class/', 'london']">
            <img
              [defaultImage]="cdnPath + '/assets/images/content/icons/square-background.webp'"
              [lazyLoad]="
                cdnPath +
                '/assets/images/content/img/cities/europe/business-class-flights-london.png'
              "
              alt="London"
              class="small" />
          </a>
          <div>
            <h2>London</h2>
            <p>Business Class</p>
            <a class="icon-link" [routerLink]="['/business-class/', 'london']"
              >from <b>$2,203</b>
              <img
                [lazyLoad]="cdnPath + '/assets/images/content/icons/arrow_forward.svg'"
                alt="Arrow Icon"
                class="bi" />
            </a>
          </div>
        </div>

        <div class="feature col pb-4 d-flex align-items-center">
          <a [routerLink]="['/business-class/', 'paris']">
            <img
              [defaultImage]="cdnPath + '/assets/images/content/icons/square-background.webp'"
              [lazyLoad]="
                cdnPath +
                '/assets/images/content/img/cities/europe/business-class-flights-paris.png'
              "
              alt="Paris"
              class="small" />
          </a>
          <div>
            <h2>Paris</h2>
            <p>Business Class</p>
            <a class="icon-link" [routerLink]="['/business-class/', 'paris']"
              >from <b>$2,095</b>
              <img
                [lazyLoad]="cdnPath + '/assets/images/content/icons/arrow_forward.svg'"
                alt="Arrow Icon"
                class="bi" />
            </a>
          </div>
        </div>

        <div class="feature col pb-4 d-flex align-items-center">
          <a [routerLink]="['/business-class/', 'amsterdam']">
            <img
              [defaultImage]="cdnPath + '/assets/images/content/icons/square-background.webp'"
              [lazyLoad]="
                cdnPath +
                '/assets/images/content/img/cities/europe/business-class-flights-amsterdam.png'
              "
              alt="Amsterdam"
              class="small" />
          </a>
          <div>
            <h2>Amsterdam</h2>
            <p>Business Class</p>
            <a class="icon-link" [routerLink]="['/business-class/', 'amsterdam']"
              >from <b>$2,123</b>
              <img
                [lazyLoad]="cdnPath + '/assets/images/content/icons/arrow_forward.svg'"
                alt="Arrow Icon"
                class="bi" />
            </a>
          </div>
        </div>

        <div class="feature col pb-4 d-flex align-items-center">
          <a [routerLink]="['/business-class/', 'rome']">
            <img
              [defaultImage]="cdnPath + '/assets/images/content/icons/square-background.webp'"
              [lazyLoad]="
                cdnPath + '/assets/images/content/img/cities/europe/business-class-flights-rome.png'
              "
              alt="Rome"
              class="small"
          /></a>
          <div>
            <h2>Rome</h2>
            <p>Business Class</p>
            <a class="icon-link" [routerLink]="['/business-class/', 'rome']"
              >from <b>$2,202</b>
              <img
                [lazyLoad]="cdnPath + '/assets/images/content/icons/arrow_forward.svg'"
                alt="Arrow Icon"
                class="bi" />
            </a>
          </div>
        </div>

        <div class="feature col pb-4 d-flex align-items-center">
          <a [routerLink]="['/business-class/', 'prague']">
            <img
              [defaultImage]="cdnPath + '/assets/images/content/icons/square-background.webp'"
              [lazyLoad]="
                cdnPath +
                '/assets/images/content/img/cities/europe/business-class-flights-prague.png'
              "
              alt="Prague"
              class="small"
          /></a>
          <div>
            <h2>Prague</h2>
            <p>Business Class</p>
            <a class="icon-link" [routerLink]="['/business-class/', 'prague']"
              >from <b>$2,257</b>
              <img
                [lazyLoad]="cdnPath + '/assets/images/content/icons/arrow_forward.svg'"
                alt="Arrow Icon"
                class="bi" />
            </a>
          </div>
        </div>

        <div class="feature col pb-4 d-flex align-items-center">
          <a [routerLink]="['/business-class/', 'barcelona']">
            <img
              [defaultImage]="cdnPath + '/assets/images/content/icons/square-background.webp'"
              [lazyLoad]="
                cdnPath +
                '/assets/images/content/img/cities/europe/business-class-flights-barcelona.png'
              "
              alt="Barcelona"
              class="small"
          /></a>
          <div>
            <h2>Barcelona</h2>
            <p>Business Class</p>
            <a class="icon-link" [routerLink]="['/business-class/', 'barcelona']"
              >from <b>$2,115</b>
              <img
                [lazyLoad]="cdnPath + '/assets/images/content/icons/arrow_forward.svg'"
                alt="Arrow Icon"
                class="bi"
            /></a>
          </div>
        </div>

        <div class="feature col pb-4 d-flex align-items-center">
          <a [routerLink]="['/business-class/', 'nice']">
            <img
              [defaultImage]="cdnPath + '/assets/images/content/icons/square-background.webp'"
              [lazyLoad]="
                cdnPath + '/assets/images/content/img/cities/europe/business-class-flights-nice.png'
              "
              alt="Nice"
              class="small"
          /></a>
          <div>
            <h2>Nice</h2>
            <p>Business Class</p>
            <a class="icon-link" [routerLink]="['/business-class/', 'nice']"
              >from <b>$2,305</b>
              <img
                [lazyLoad]="cdnPath + '/assets/images/content/icons/arrow_forward.svg'"
                alt="Arrow Icon"
                class="bi"
            /></a>
          </div>
        </div>

        <div class="feature col pb-4 d-flex align-items-center">
          <a [routerLink]="['/business-class/', 'zurich']">
            <img
              [defaultImage]="cdnPath + '/assets/images/content/icons/square-background.webp'"
              [lazyLoad]="
                cdnPath +
                '/assets/images/content/img/cities/europe/business-class-flights-zurich.png'
              "
              alt="Zurich"
              class="small"
          /></a>
          <div>
            <h2>Zurich</h2>
            <p>Business Class</p>
            <a class="icon-link" [routerLink]="['/business-class/', 'zurich']"
              >from <b>$2,245</b>
              <img
                [lazyLoad]="cdnPath + '/assets/images/content/icons/arrow_forward.svg'"
                alt="Arrow Icon"
                class="bi"
            /></a>
          </div>
        </div>

        <div class="feature col pb-4 d-flex align-items-center">
          <a [routerLink]="['/business-class/', 'milan']">
            <img
              [defaultImage]="cdnPath + '/assets/images/content/icons/square-background.webp'"
              [lazyLoad]="
                cdnPath +
                '/assets/images/content/img/cities/europe/business-class-flights-milan.png'
              "
              alt="Milan"
              class="small"
          /></a>
          <div>
            <h2>Milan</h2>
            <p>Business Class</p>
            <a class="icon-link" [routerLink]="['/business-class/', 'milan']"
              >from <b>$2,154</b>
              <img
                [lazyLoad]="cdnPath + '/assets/images/content/icons/arrow_forward.svg'"
                alt="Arrow Icon"
                class="bi"
            /></a>
          </div>
        </div>

        <div class="feature col pb-4 d-flex align-items-center">
          <a [routerLink]="['/business-class/', 'manchester']">
            <img
              [defaultImage]="cdnPath + '/assets/images/content/icons/square-background.webp'"
              [lazyLoad]="
                cdnPath +
                '/assets/images/content/img/cities/europe/business-class-flights-manchester.png'
              "
              alt="Manchester"
              class="small"
          /></a>
          <div>
            <h2>Manchester</h2>
            <p>Business Class</p>
            <a class="icon-link" [routerLink]="['/business-class/', 'manchester']"
              >from <b>$2,133</b>
              <img
                [lazyLoad]="cdnPath + '/assets/images/content/icons/arrow_forward.svg'"
                alt="Arrow Icon"
                class="bi"
            /></a>
          </div>
        </div>

        <div class="feature col pb-4 d-flex align-items-center">
          <a [routerLink]="['/business-class/', 'luxembourg']">
            <img
              [defaultImage]="cdnPath + '/assets/images/content/icons/square-background.webp'"
              [lazyLoad]="
                cdnPath +
                '/assets/images/content/img/cities/europe/business-class-flights-luxembourg.png'
              "
              alt="Luxembourg"
              class="small"
          /></a>
          <div>
            <h2>Luxembourg</h2>
            <p>Business Class</p>
            <a class="icon-link" [routerLink]="['/business-class/', 'luxembourg']"
              >from <b>$2,241</b>
              <img
                [lazyLoad]="cdnPath + '/assets/images/content/icons/arrow_forward.svg'"
                alt="Arrow Icon"
                class="bi"
            /></a>
          </div>
        </div>

        <div class="feature col pb-4 d-flex align-items-center">
          <a [routerLink]="['/business-class/', 'istanbul']">
            <img
              [defaultImage]="cdnPath + '/assets/images/content/icons/square-background.webp'"
              [lazyLoad]="
                cdnPath +
                '/assets/images/content/img/cities/europe/business-class-flights-istanbul.png'
              "
              alt="Istanbul"
              class="small"
          /></a>
          <div>
            <h2>Istanbul</h2>
            <p>Business Class</p>
            <a class="icon-link" [routerLink]="['/business-class/', 'istanbul']"
              >from <b>$2,325</b>
              <img
                [lazyLoad]="cdnPath + '/assets/images/content/icons/arrow_forward.svg'"
                alt="Arrow Icon"
                class="bi"
            /></a>
          </div>
        </div>

        <div class="feature col pb-4 d-flex align-items-center">
          <a [routerLink]="['/business-class/', 'warsaw']">
            <img
              [defaultImage]="cdnPath + '/assets/images/content/icons/square-background.webp'"
              [lazyLoad]="
                cdnPath +
                '/assets/images/content/img/cities/europe/business-class-flights-warsaw.png'
              "
              alt="Warsaw"
              class="small"
          /></a>
          <div>
            <h2>Warsaw</h2>
            <p>Business Class</p>
            <a class="icon-link" [routerLink]="['/business-class/', 'warsaw']"
              >from <b>$2,230</b>
              <img
                [lazyLoad]="cdnPath + '/assets/images/content/icons/arrow_forward.svg'"
                alt="Arrow Icon"
                class="bi"
            /></a>
          </div>
        </div>

        <div class="feature col pb-4 d-flex align-items-center">
          <a [routerLink]="['/business-class/', 'budapest']">
            <img
              [defaultImage]="cdnPath + '/assets/images/content/icons/square-background.webp'"
              [lazyLoad]="
                cdnPath +
                '/assets/images/content/img/cities/europe/business-class-flights-budapest.png'
              "
              alt="Budapest"
              class="small"
          /></a>
          <div>
            <h2>Budapest</h2>
            <p>Business Class</p>
            <a class="icon-link" [routerLink]="['/business-class/', 'budapest']"
              >from <b>$2,122</b>
              <img
                [lazyLoad]="cdnPath + '/assets/images/content/icons/arrow_forward.svg'"
                alt="Arrow Icon"
                class="bi"
            /></a>
          </div>
        </div>

        <div class="feature col pb-4 d-flex align-items-center">
          <a [routerLink]="['/business-class/', 'vienna']">
            <img
              [defaultImage]="cdnPath + '/assets/images/content/icons/square-background.webp'"
              [lazyLoad]="
                cdnPath +
                '/assets/images/content/img/cities/europe/business-class-flights-vienna.png'
              "
              alt="Vienna"
              class="small"
          /></a>
          <div>
            <h2>Vienna</h2>
            <p>Business Class</p>
            <a class="icon-link" [routerLink]="['/business-class/', 'vienna']"
              >from <b>$2,277</b>
              <img
                [lazyLoad]="cdnPath + '/assets/images/content/icons/arrow_forward.svg'"
                alt="Arrow Icon"
                class="bi"
            /></a>
          </div>
        </div>

        <div class="feature col pb-4 d-flex align-items-center">
          <a [routerLink]="['/business-class/', 'santorini']">
            <img
              [defaultImage]="cdnPath + '/assets/images/content/icons/square-background.webp'"
              [lazyLoad]="
                cdnPath +
                '/assets/images/content/img/cities/europe/business-class-flights-santorini.png'
              "
              alt="Santorini"
              class="small"
          /></a>
          <div>
            <h2>Santorini</h2>
            <p>Business Class</p>
            <a class="icon-link" [routerLink]="['/business-class/', 'santorini']"
              >from <b>$2,235</b>
              <img
                [lazyLoad]="cdnPath + '/assets/images/content/icons/arrow_forward.svg'"
                alt="Arrow Icon"
                class="bi"
            /></a>
          </div>
        </div>

        <div class="feature col pb-4 d-flex align-items-center">
          <a [routerLink]="['/business-class/', 'lyon']">
            <img
              [defaultImage]="cdnPath + '/assets/images/content/icons/square-background.webp'"
              [lazyLoad]="
                cdnPath + '/assets/images/content/img/cities/europe/business-class-flights-lyon.png'
              "
              alt="Lyon"
              class="small"
          /></a>
          <div>
            <h2>Lyon</h2>
            <p>Business Class</p>
            <a class="icon-link" [routerLink]="['/business-class/', 'lyon']"
              >from <b>$2,305</b>
              <img
                [lazyLoad]="cdnPath + '/assets/images/content/icons/arrow_forward.svg'"
                alt="Arrow Icon"
                class="bi"
            /></a>
          </div>
        </div>

        <div class="feature col pb-4 d-flex align-items-center">
          <a [routerLink]="['/business-class/', 'madrid']">
            <img
              [defaultImage]="cdnPath + '/assets/images/content/icons/square-background.webp'"
              [lazyLoad]="
                cdnPath +
                '/assets/images/content/img/cities/europe/business-class-flights-madrid.png'
              "
              alt="Madrid"
              class="small"
          /></a>
          <div>
            <h2>Madrid</h2>
            <p>Business Class</p>
            <a class="icon-link" [routerLink]="['/business-class/', 'madrid']"
              >from <b>$2,174</b>
              <img
                [lazyLoad]="cdnPath + '/assets/images/content/icons/arrow_forward.svg'"
                alt="Arrow Icon"
                class="bi"
            /></a>
          </div>
        </div>

        <div class="feature col pb-4 d-flex align-items-center">
          <a [routerLink]="['/business-class/', 'munich']">
            <img
              [defaultImage]="cdnPath + '/assets/images/content/icons/square-background.webp'"
              [lazyLoad]="
                cdnPath +
                '/assets/images/content/img/cities/europe/business-class-flights-munich.png'
              "
              alt="Munich"
              class="small" />
          </a>
          <div>
            <h2>Munich</h2>
            <p>Business Class</p>
            <a class="icon-link" [routerLink]="['/business-class/', 'munich']"
              >from <b>$2,345</b>
              <img
                [lazyLoad]="cdnPath + '/assets/images/content/icons/arrow_forward.svg'"
                alt="Arrow Icon"
                class="bi"
            /></a>
          </div>
        </div>

        <div class="feature col pb-4 d-flex align-items-center">
          <a [routerLink]="['/business-class/', 'dublin']">
            <img
              [defaultImage]="cdnPath + '/assets/images/content/icons/square-background.webp'"
              [lazyLoad]="
                cdnPath +
                '/assets/images/content/img/cities/europe/business-class-flights-dublin.png'
              "
              alt="Dublin"
              class="small"
          /></a>
          <div>
            <h2>Dublin</h2>
            <p>Business Class</p>
            <a class="icon-link" [routerLink]="['/business-class/', 'dublin']"
              >from <b>$2,181</b>
              <img
                [lazyLoad]="cdnPath + '/assets/images/content/icons/arrow_forward.svg'"
                alt="Arrow Icon"
                class="bi"
            /></a>
          </div>
        </div>

        <div class="feature col pb-4 d-flex align-items-center">
          <a [routerLink]="['/business-class/', 'oslo']">
            <img
              [defaultImage]="cdnPath + '/assets/images/content/icons/square-background.webp'"
              [lazyLoad]="
                cdnPath + '/assets/images/content/img/cities/europe/business-class-flights-oslo.png'
              "
              alt="Oslo"
              class="small"
          /></a>
          <div>
            <h2>Oslo</h2>
            <p>Business Class</p>
            <a class="icon-link" [routerLink]="['/business-class/', 'oslo']"
              >from <b>$2,134</b>
              <img
                [lazyLoad]="cdnPath + '/assets/images/content/icons/arrow_forward.svg'"
                alt="Arrow Icon"
                class="bi"
            /></a>
          </div>
        </div>

        <div class="feature col pb-4 d-flex align-items-center">
          <a [routerLink]="['/business-class/', 'copenhagen']">
            <img
              [defaultImage]="cdnPath + '/assets/images/content/icons/square-background.webp'"
              [lazyLoad]="
                cdnPath +
                '/assets/images/content/img/cities/europe/business-class-flights-copenhagen.png'
              "
              alt="Copenhagen"
              class="small"
          /></a>
          <div>
            <h2>Copenhagen</h2>
            <p>Business Class</p>
            <a class="icon-link" [routerLink]="['/business-class/', 'copenhagen']"
              >from <b>$2,294</b>
              <img
                [lazyLoad]="cdnPath + '/assets/images/content/icons/arrow_forward.svg'"
                alt="Arrow Icon"
                class="bi"
            /></a>
          </div>
        </div>

        <div class="feature col pb-4 d-flex align-items-center">
          <a [routerLink]="['/business-class/', 'venice']">
            <img
              [defaultImage]="cdnPath + '/assets/images/content/icons/square-background.webp'"
              [lazyLoad]="
                cdnPath +
                '/assets/images/content/img/cities/europe/business-class-flights-venice.png'
              "
              alt="Venice"
              class="small" />
          </a>
          <div>
            <h2>Venice</h2>
            <p>Business Class</p>
            <a class="icon-link" [routerLink]="['/business-class/', 'venice']"
              >from <b>$2,201</b>
              <img
                [lazyLoad]="cdnPath + '/assets/images/content/icons/arrow_forward.svg'"
                alt="Arrow Icon"
                class="bi"
            /></a>
          </div>
        </div>
      </div>
    </div>
  </section>
</ng-template>

<ng-template #asia>
  <section id="cities-header">
    <div class="container col-xl-12 px-4 py-5">
      <div class="row align-items-center">
        <h1 class="fw-bold lh-1 mb-3">Cheap Business Class Tickets To Asia</h1>
      </div>
    </div>
  </section>

  <section id="deals">
    <div class="container col-xl-12">
      <div class="row g-4 pb-5 row-cols-1 row-cols-md-2 row-cols-lg-3">
        <div class="feature col pb-4 d-flex align-items-center">
          <a [routerLink]="['/business-class/', 'shanghai']">
            <img
              [defaultImage]="cdnPath + '/assets/images/content/icons/square-background.webp'"
              [lazyLoad]="
                cdnPath +
                '/assets/images/content/img/cities/asia/business-class-flights-shanghai.png'
              "
              alt="Shanghai"
              class="small"
          /></a>
          <div>
            <h2>Shanghai</h2>
            <p>Business Class</p>
            <a class="icon-link" [routerLink]="['/business-class/', 'shanghai']"
              >from <b>$3,005</b>
              <img
                [lazyLoad]="cdnPath + '/assets/images/content/icons/arrow_forward.svg'"
                alt="Arrow Icon"
                class="bi"
            /></a>
          </div>
        </div>

        <div class="feature col pb-4 d-flex align-items-center">
          <a [routerLink]="['/business-class/', 'bangkok']">
            <img
              [defaultImage]="cdnPath + '/assets/images/content/icons/square-background.webp'"
              [lazyLoad]="
                cdnPath +
                '/assets/images/content/img/cities/asia/business-class-flights-bangkok.png'
              "
              alt="Bangkok"
              class="small"
          /></a>
          <div>
            <h2>Bangkok</h2>
            <p>Business Class</p>
            <a class="icon-link" [routerLink]="['/business-class/', 'bangkok']"
              >from <b>$3,010</b>
              <img
                [lazyLoad]="cdnPath + '/assets/images/content/icons/arrow_forward.svg'"
                alt="Arrow Icon"
                class="bi"
            /></a>
          </div>
        </div>

        <div class="feature col pb-4 d-flex align-items-center">
          <a [routerLink]="['/business-class/', 'delhi']">
            <img
              [defaultImage]="cdnPath + '/assets/images/content/icons/square-background.webp'"
              [lazyLoad]="
                cdnPath + '/assets/images/content/img/cities/asia/business-class-flights-delhi.png'
              "
              alt="Delhi"
              class="small"
          /></a>
          <div>
            <h2>Delhi</h2>
            <p>Business Class</p>
            <a class="icon-link" [routerLink]="['/business-class/', 'delhi']"
              >from <b>$3,155</b>
              <img
                [lazyLoad]="cdnPath + '/assets/images/content/icons/arrow_forward.svg'"
                alt="Arrow Icon"
                class="bi"
            /></a>
          </div>
        </div>

        <div class="feature col pb-4 d-flex align-items-center">
          <a [routerLink]="['/business-class/', 'hong-kong']">
            <img
              [defaultImage]="cdnPath + '/assets/images/content/icons/square-background.webp'"
              [lazyLoad]="
                cdnPath +
                '/assets/images/content/img/cities/asia/business-class-flights-hong-kong.png'
              "
              alt="Hong Kong"
              class="small"
          /></a>
          <div>
            <h2>Hong Kong</h2>
            <p>Business Class</p>
            <a class="icon-link" [routerLink]="['/business-class/', 'hong-kong']"
              >from <b>$3,140</b>
              <img
                [lazyLoad]="cdnPath + '/assets/images/content/icons/arrow_forward.svg'"
                alt="Arrow Icon"
                class="bi"
            /></a>
          </div>
        </div>

        <div class="feature col pb-4 d-flex align-items-center">
          <a [routerLink]="['/business-class/', 'tokyo']">
            <img
              [defaultImage]="cdnPath + '/assets/images/content/icons/square-background.webp'"
              [lazyLoad]="
                cdnPath + '/assets/images/content/img/cities/asia/business-class-flights-tokyo.png'
              "
              alt="Tokyo"
              class="small"
          /></a>
          <div>
            <h2>Tokyo</h2>
            <p>Business Class</p>
            <a class="icon-link" [routerLink]="['/business-class/', 'tokyo']"
              >from <b>$3,129</b>
              <img
                [lazyLoad]="cdnPath + '/assets/images/content/icons/arrow_forward.svg'"
                alt="Arrow Icon"
                class="bi"
            /></a>
          </div>
        </div>

        <div class="feature col pb-4 d-flex align-items-center">
          <a [routerLink]="['/business-class/', 'beijing']">
            <img
              [defaultImage]="cdnPath + '/assets/images/content/icons/square-background.webp'"
              [lazyLoad]="
                cdnPath +
                '/assets/images/content/img/cities/asia/business-class-flights-beijing.png'
              "
              alt="Beijing"
              class="small"
          /></a>
          <div>
            <h2>Beijing</h2>
            <p>Business Class</p>
            <a class="icon-link" [routerLink]="['/business-class/', 'beijing']"
              >from <b>$3,131</b>
              <img
                [lazyLoad]="cdnPath + '/assets/images/content/icons/arrow_forward.svg'"
                alt="Arrow Icon"
                class="bi"
            /></a>
          </div>
        </div>

        <div class="feature col pb-4 d-flex align-items-center">
          <a [routerLink]="['/business-class/', 'seoul']">
            <img
              [defaultImage]="cdnPath + '/assets/images/content/icons/square-background.webp'"
              [lazyLoad]="
                cdnPath + '/assets/images/content/img/cities/asia/business-class-flights-seoul.png'
              "
              alt="Seoul"
              class="small"
          /></a>
          <div>
            <h2>Seoul</h2>
            <p>Business Class</p>
            <a class="icon-link" [routerLink]="['/business-class/', 'seoul']"
              >from <b>$3,237</b>
              <img
                [lazyLoad]="cdnPath + '/assets/images/content/icons/arrow_forward.svg'"
                alt="Arrow Icon"
                class="bi"
            /></a>
          </div>
        </div>

        <div class="feature col pb-4 d-flex align-items-center">
          <a [routerLink]="['/business-class/', 'mumbai']">
            <img
              [defaultImage]="cdnPath + '/assets/images/content/icons/square-background.webp'"
              [lazyLoad]="
                cdnPath + '/assets/images/content/img/cities/asia/business-class-flights-mumbai.png'
              "
              alt="Mumbai"
              class="small"
          /></a>
          <div>
            <h2>Mumbai</h2>
            <p>Business Class</p>
            <a class="icon-link" [routerLink]="['/business-class/', 'mumbai']"
              >from <b>$3,240</b>
              <img
                [lazyLoad]="cdnPath + '/assets/images/content/icons/arrow_forward.svg'"
                alt="Arrow Icon"
                class="bi"
            /></a>
          </div>
        </div>

        <div class="feature col pb-4 d-flex align-items-center">
          <a [routerLink]="['/business-class/', 'guangzhou']">
            <img
              [defaultImage]="cdnPath + '/assets/images/content/icons/square-background.webp'"
              [lazyLoad]="
                cdnPath +
                '/assets/images/content/img/cities/asia/business-class-flights-guangzhou.png'
              "
              alt="Guangzhou"
              class="small"
          /></a>
          <div>
            <h2>Guangzhou</h2>
            <p>Business Class</p>
            <a class="icon-link" [routerLink]="['/business-class/', 'guangzhou']"
              >from <b>$3,238</b>
              <img
                [lazyLoad]="cdnPath + '/assets/images/content/icons/arrow_forward.svg'"
                alt="Arrow Icon"
                class="bi"
            /></a>
          </div>
        </div>

        <div class="feature col pb-4 d-flex align-items-center">
          <a [routerLink]="['/business-class/', 'taiwan']">
            <img
              [defaultImage]="cdnPath + '/assets/images/content/icons/square-background.webp'"
              [lazyLoad]="
                cdnPath + '/assets/images/content/img/cities/asia/business-class-flights-taiwan.png'
              "
              alt="Taiwan"
              class="small"
          /></a>
          <div>
            <h2>Taiwan</h2>
            <p>Business Class</p>
            <a class="icon-link" [routerLink]="['/business-class/', 'taiwan']"
              >from <b>$3,231</b>
              <img
                [lazyLoad]="cdnPath + '/assets/images/content/icons/arrow_forward.svg'"
                alt="Arrow Icon"
                class="bi"
            /></a>
          </div>
        </div>

        <div class="feature col pb-4 d-flex align-items-center">
          <a [routerLink]="['/business-class/', 'singapore']">
            <img
              [defaultImage]="cdnPath + '/assets/images/content/icons/square-background.webp'"
              [lazyLoad]="
                cdnPath +
                '/assets/images/content/img/cities/asia/business-class-flights-singapore.png'
              "
              alt="Singapore"
              class="small"
          /></a>
          <div>
            <h2>Singapore</h2>
            <p>Business Class</p>
            <a class="icon-link" [routerLink]="['/business-class/', 'singapore']"
              >from <b>$3,103</b>
              <img
                [lazyLoad]="cdnPath + '/assets/images/content/icons/arrow_forward.svg'"
                alt="Arrow Icon"
                class="bi"
            /></a>
          </div>
        </div>

        <div class="feature col pb-4 d-flex align-items-center">
          <a [routerLink]="['/business-class/', 'taipei']">
            <img
              [defaultImage]="cdnPath + '/assets/images/content/icons/square-background.webp'"
              [lazyLoad]="
                cdnPath + '/assets/images/content/img/cities/asia/business-class-flights-taipei.png'
              "
              alt="Taipei"
              class="small"
          /></a>
          <div>
            <h2>Taipei</h2>
            <p>Business Class</p>
            <a class="icon-link" [routerLink]="['/business-class/', 'taipei']"
              >from <b>$3,185</b>
              <img
                [lazyLoad]="cdnPath + '/assets/images/content/icons/arrow_forward.svg'"
                alt="Arrow Icon"
                class="bi"
            /></a>
          </div>
        </div>
      </div>
    </div>
  </section>
</ng-template>

<ng-template #africaMiddleEast>
  <section id="cities-header">
    <div class="container col-xl-12 px-4 py-5">
      <div class="row align-items-center">
        <h1 class="fw-bold lh-1 mb-3">Cheap Business Class Tickets To Africa & Middle East</h1>
      </div>
    </div>
  </section>

  <section id="deals">
    <div class="container col-xl-12">
      <div class="row g-4 pb-5 row-cols-1 row-cols-md-2 row-cols-lg-3">
        <div class="feature col pb-4 d-flex align-items-center">
          <a [routerLink]="['/business-class/', 'dubai']">
            <img
              [defaultImage]="cdnPath + '/assets/images/content/icons/square-background.webp'"
              [lazyLoad]="
                cdnPath +
                '/assets/images/content/img/cities/africa-middle-east/business-class-flights-abu-dhabi.png'
              "
              alt="Dubai"
              class="small"
          /></a>
          <div>
            <h2>Dubai</h2>
            <p>Business Class</p>
            <a class="icon-link" [routerLink]="['/business-class/', 'dubai']"
              >from <b>$3,210</b>
              <img
                [lazyLoad]="cdnPath + '/assets/images/content/icons/arrow_forward.svg'"
                alt="Arrow Icon"
                class="bi"
            /></a>
          </div>
        </div>

        <div class="feature col pb-4 d-flex align-items-center">
          <a [routerLink]="['/business-class/', 'johannesburg']">
            <img
              [defaultImage]="cdnPath + '/assets/images/content/icons/square-background.webp'"
              [lazyLoad]="
                cdnPath +
                '/assets/images/content/img/cities/africa-middle-east/business-class-flights-johannesburg.png'
              "
              alt="Johannesburg"
              class="small"
          /></a>
          <div>
            <h2>Johannesburg</h2>
            <p>Business Class</p>
            <a class="icon-link" [routerLink]="['/business-class/', 'johannesburg']"
              >from <b>$3,039</b>
              <img
                [lazyLoad]="cdnPath + '/assets/images/content/icons/arrow_forward.svg'"
                alt="Arrow Icon"
                class="bi"
            /></a>
          </div>
        </div>

        <div class="feature col pb-4 d-flex align-items-center">
          <a [routerLink]="['/business-class/', 'abu-dhabi']">
            <img
              [defaultImage]="cdnPath + '/assets/images/content/icons/square-background.webp'"
              [lazyLoad]="
                cdnPath +
                '/assets/images/content/img/cities/africa-middle-east/business-class-flights-abu-dhabi.png'
              "
              alt="Abu Dhabi"
              class="small"
          /></a>
          <div>
            <h2>Abu Dhabi</h2>
            <p>Business Class</p>
            <a class="icon-link" [routerLink]="['/business-class/', 'abu-dhabi']"
              >from <b>$2,940</b>
              <img
                [lazyLoad]="cdnPath + '/assets/images/content/icons/arrow_forward.svg'"
                alt="Arrow Icon"
                class="bi"
            /></a>
          </div>
        </div>

        <div class="feature col pb-4 d-flex align-items-center">
          <a [routerLink]="['/business-class/', 'cape-town']">
            <img
              [defaultImage]="cdnPath + '/assets/images/content/icons/square-background.webp'"
              [lazyLoad]="
                cdnPath +
                '/assets/images/content/img/cities/africa-middle-east/business-class-flights-cape-town.png'
              "
              alt="Cape Town"
              class="small"
          /></a>
          <div>
            <h2>Cape Town</h2>
            <p>Business Class</p>
            <a class="icon-link" [routerLink]="['/business-class/', 'cape-town']"
              >from <b>$3,201</b>
              <img
                [lazyLoad]="cdnPath + '/assets/images/content/icons/arrow_forward.svg'"
                alt="Arrow Icon"
                class="bi"
            /></a>
          </div>
        </div>

        <div class="feature col pb-4 d-flex align-items-center">
          <a [routerLink]="['/business-class/', 'istanbul']">
            <img
              [defaultImage]="cdnPath + '/assets/images/content/icons/square-background.webp'"
              [lazyLoad]="
                cdnPath +
                '/assets/images/content/img/cities/africa-middle-east/business-class-flights-istanbul.png'
              "
              alt="Istanbul"
              class="small"
          /></a>
          <div>
            <h2>Istanbul</h2>
            <p>Business Class</p>
            <a class="icon-link" [routerLink]="['/business-class/', 'istanbul']"
              >from <b>$2,439</b>
              <img
                [lazyLoad]="cdnPath + '/assets/images/content/icons/arrow_forward.svg'"
                alt="Arrow Icon"
                class="bi"
            /></a>
          </div>
        </div>

        <div class="feature col pb-4 d-flex align-items-center">
          <a [routerLink]="['/business-class/', 'cairo']">
            <img
              [defaultImage]="cdnPath + '/assets/images/content/icons/square-background.webp'"
              [lazyLoad]="
                cdnPath +
                '/assets/images/content/img/cities/africa-middle-east/business-class-flights-cairo.png'
              "
              alt="Cairo"
              class="small"
          /></a>
          <div>
            <h2>Cairo</h2>
            <p>Business Class</p>
            <a class="icon-link" [routerLink]="['/business-class/', 'cairo']"
              >from <b>$2,806</b>
              <img
                [lazyLoad]="cdnPath + '/assets/images/content/icons/arrow_forward.svg'"
                alt="Arrow Icon"
                class="bi"
            /></a>
          </div>
        </div>

        <div class="feature col pb-4 d-flex align-items-center">
          <a [routerLink]="['/business-class/', 'tel-aviv']">
            <img
              [defaultImage]="cdnPath + '/assets/images/content/icons/square-background.webp'"
              [lazyLoad]="
                cdnPath +
                '/assets/images/content/img/cities/africa-middle-east/business-class-flights-tel-aviv.png'
              "
              alt="Tel Aviv"
              class="small"
          /></a>
          <div>
            <h2>Tel Aviv</h2>
            <p>Business Class</p>
            <a class="icon-link" [routerLink]="['/business-class/', 'tel-aviv']"
              >from <b>$2,389</b>
              <img
                [lazyLoad]="cdnPath + '/assets/images/content/icons/arrow_forward.svg'"
                alt="Arrow Icon"
                class="bi"
            /></a>
          </div>
        </div>

        <div class="feature col pb-4 d-flex align-items-center">
          <a [routerLink]="['/business-class/', 'nairobi']">
            <img
              [defaultImage]="cdnPath + '/assets/images/content/icons/square-background.webp'"
              [lazyLoad]="
                cdnPath +
                '/assets/images/content/img/cities/africa-middle-east/business-class-flights-nairobi.png'
              "
              alt="Nairobi"
              class="small"
          /></a>
          <div>
            <h2>Nairobi</h2>
            <p>Business Class</p>
            <a class="icon-link" [routerLink]="['/business-class/', 'nairobi']"
              >from <b>$3,209</b>
              <img
                [lazyLoad]="cdnPath + '/assets/images/content/icons/arrow_forward.svg'"
                alt="Arrow Icon"
                class="bi"
            /></a>
          </div>
        </div>

        <div class="feature col pb-4 d-flex align-items-center">
          <a [routerLink]="['/business-class/', 'doha']">
            <img
              [defaultImage]="cdnPath + '/assets/images/content/icons/square-background.webp'"
              [lazyLoad]="
                cdnPath +
                '/assets/images/content/img/cities/africa-middle-east/business-class-flights-doha.png'
              "
              alt="Doha"
              class="small"
          /></a>
          <div>
            <h2>Doha</h2>
            <p>Business Class</p>
            <a class="icon-link" [routerLink]="['/business-class/', 'doha']"
              >from <b>$2,792</b>
              <img
                [lazyLoad]="cdnPath + '/assets/images/content/icons/arrow_forward.svg'"
                alt="Arrow Icon"
                class="bi"
            /></a>
          </div>
        </div>

        <div class="feature col pb-4 d-flex align-items-center">
          <a [routerLink]="['/business-class/', 'kuwait']">
            <img
              [defaultImage]="cdnPath + '/assets/images/content/icons/square-background.webp'"
              [lazyLoad]="
                cdnPath +
                '/assets/images/content/img/cities/africa-middle-east/business-class-flights-kuwait.png'
              "
              alt="Kuwait"
              class="small"
          /></a>
          <div>
            <h2>Kuwait</h2>
            <p>Business Class</p>
            <a class="icon-link" [routerLink]="['/business-class/', 'kuwait']"
              >from <b>$3,025</b>
              <img
                [lazyLoad]="cdnPath + '/assets/images/content/icons/arrow_forward.svg'"
                alt="Arrow Icon"
                class="bi"
            /></a>
          </div>
        </div>

        <div class="feature col pb-4 d-flex align-items-center">
          <a [routerLink]="['/business-class/', 'beirut']">
            <img
              [defaultImage]="cdnPath + '/assets/images/content/icons/square-background.webp'"
              [lazyLoad]="
                cdnPath +
                '/assets/images/content/img/cities/africa-middle-east/business-class-flights-beirut.png'
              "
              alt="Beirut"
              class="small"
          /></a>
          <div>
            <h2>Beirut</h2>
            <p>Business Class</p>
            <a class="icon-link" [routerLink]="['/business-class/', 'beirut']"
              >from <b>$3,210</b>
              <img
                [lazyLoad]="cdnPath + '/assets/images/content/icons/arrow_forward.svg'"
                alt="Arrow Icon"
                class="bi"
            /></a>
          </div>
        </div>

        <div class="feature col pb-4 d-flex align-items-center">
          <a [routerLink]="['/business-class/', 'kilimanjaro']">
            <img
              [defaultImage]="cdnPath + '/assets/images/content/icons/square-background.webp'"
              [lazyLoad]="
                cdnPath +
                '/assets/images/content/img/cities/africa-middle-east/business-class-flights-kilimanjaro.png'
              "
              alt="Kilimanjaro"
              class="small"
          /></a>
          <div>
            <h2>Kilimanjaro</h2>
            <p>Business Class</p>
            <a class="icon-link" [routerLink]="['/business-class/', 'kilimanjaro']"
              >from <b>$3,420</b>
              <img
                [lazyLoad]="cdnPath + '/assets/images/content/icons/arrow_forward.svg'"
                alt="Arrow Icon"
                class="bi"
            /></a>
          </div>
        </div>
      </div>
    </div>
  </section>
</ng-template>

<ng-template #ocenia>
  <section id="cities-header">
    <div class="container col-xl-12 px-4 py-5">
      <div class="row align-items-center">
        <h1 class="fw-bold lh-1 mb-3">Cheap Business Class Tickets To Oceania</h1>
      </div>
    </div>
  </section>

  <section id="deals">
    <div class="container col-xl-12">
      <div class="row g-4 pb-5 row-cols-1 row-cols-md-2 row-cols-lg-3">
        <div class="feature col pb-4 d-flex align-items-center">
          <a [routerLink]="['/business-class/', 'adelaide']">
            <img
              [defaultImage]="cdnPath + '/assets/images/content/icons/square-background.webp'"
              [lazyLoad]="
                cdnPath +
                '/assets/images/content/img/cities/oceania/business-class-flights-adelaide.png'
              "
              alt="Adelaide"
              class="small"
          /></a>
          <div>
            <h2>Adelaide</h2>
            <p>Business Class</p>
            <a class="icon-link" [routerLink]="['/business-class/', 'adelaide']"
              >from <b>$2,439</b>
              <img
                [lazyLoad]="cdnPath + '/assets/images/content/icons/arrow_forward.svg'"
                alt="Arrow Icon"
                class="bi"
            /></a>
          </div>
        </div>

        <div class="feature col pb-4 d-flex align-items-center">
          <a [routerLink]="['/business-class/', 'cairns']">
            <img
              [defaultImage]="cdnPath + '/assets/images/content/icons/square-background.webp'"
              [lazyLoad]="
                cdnPath +
                '/assets/images/content/img/cities/oceania/business-class-flights-cairns.png'
              "
              alt="Cairns"
              class="small"
          /></a>
          <div>
            <h2>Cairns</h2>
            <p>Business Class</p>
            <a class="icon-link" [routerLink]="['/business-class/', 'cairns']"
              >from <b>$2,690</b>
              <img
                [lazyLoad]="cdnPath + '/assets/images/content/icons/arrow_forward.svg'"
                alt="Arrow Icon"
                class="bi"
            /></a>
          </div>
        </div>

        <div class="feature col pb-4 d-flex align-items-center">
          <a [routerLink]="['/business-class/', 'sydney']">
            <img
              [defaultImage]="cdnPath + '/assets/images/content/icons/square-background.webp'"
              [lazyLoad]="
                cdnPath +
                '/assets/images/content/img/cities/oceania/business-class-flights-sydney.png'
              "
              alt="Sydney"
              class="small"
          /></a>
          <div>
            <h2>Sydney</h2>
            <p>Business Class</p>
            <a class="icon-link" [routerLink]="['/business-class/', 'sydney']"
              >from <b>$2,830</b>
              <img
                [lazyLoad]="cdnPath + '/assets/images/content/icons/arrow_forward.svg'"
                alt="Arrow Icon"
                class="bi"
            /></a>
          </div>
        </div>

        <div class="feature col pb-4 d-flex align-items-center">
          <a [routerLink]="['/business-class/', 'auckland']">
            <img
              [defaultImage]="cdnPath + '/assets/images/content/icons/square-background.webp'"
              [lazyLoad]="
                cdnPath +
                '/assets/images/content/img/cities/oceania/business-class-flights-auckland.png'
              "
              alt="Auckland"
              class="small"
          /></a>
          <div>
            <h2>Auckland</h2>
            <p>Business Class</p>
            <a class="icon-link" [routerLink]="['/business-class/', 'auckland']"
              >from <b>$2,039</b>
              <img
                [lazyLoad]="cdnPath + '/assets/images/content/icons/arrow_forward.svg'"
                alt="Arrow Icon"
                class="bi"
            /></a>
          </div>
        </div>

        <div class="feature col pb-4 d-flex align-items-center">
          <a [routerLink]="['/business-class/', 'melbourne']">
            <img
              [defaultImage]="cdnPath + '/assets/images/content/icons/square-background.webp'"
              [lazyLoad]="
                cdnPath +
                '/assets/images/content/img/cities/oceania/business-class-flights-melbourne.png'
              "
              alt="Melbourne"
              class="small"
          /></a>
          <div>
            <h2>Melbourne</h2>
            <p>Business Class</p>
            <a class="icon-link" [routerLink]="['/business-class/', 'melbourne']"
              >from <b>$2,720</b>
              <img
                [lazyLoad]="cdnPath + '/assets/images/content/icons/arrow_forward.svg'"
                alt="Arrow Icon"
                class="bi"
            /></a>
          </div>
        </div>

        <div class="feature col pb-4 d-flex align-items-center">
          <a [routerLink]="['/business-class/', 'wellington']">
            <img
              [defaultImage]="cdnPath + '/assets/images/content/icons/square-background.webp'"
              [lazyLoad]="
                cdnPath +
                '/assets/images/content/img/cities/oceania/business-class-flights-wellington.png'
              "
              alt="Wellington"
              class="small"
          /></a>
          <div>
            <h2>Wellington</h2>
            <p>Business Class</p>
            <a class="icon-link" [routerLink]="['/business-class/', 'wellington']"
              >from <b>$2,502</b>
              <img
                [lazyLoad]="cdnPath + '/assets/images/content/icons/arrow_forward.svg'"
                alt="Arrow Icon"
                class="bi"
            /></a>
          </div>
        </div>

        <div class="feature col pb-4 d-flex align-items-center">
          <a [routerLink]="['/business-class/', 'brisbane']">
            <img
              [defaultImage]="cdnPath + '/assets/images/content/icons/square-background.webp'"
              [lazyLoad]="
                cdnPath +
                '/assets/images/content/img/cities/oceania/business-class-flights-brisbane.png'
              "
              alt="Brisbane"
              class="small"
          /></a>
          <div>
            <h2>Brisbane</h2>
            <p>Business Class</p>
            <a class="icon-link" [routerLink]="['/business-class/', 'brisbane']"
              >from <b>$2,272</b>
              <img
                [lazyLoad]="cdnPath + '/assets/images/content/icons/arrow_forward.svg'"
                alt="Arrow Icon"
                class="bi"
            /></a>
          </div>
        </div>

        <div class="feature col pb-4 d-flex align-items-center">
          <a [routerLink]="['/business-class/', 'perth']">
            <img
              [defaultImage]="cdnPath + '/assets/images/content/icons/square-background.webp'"
              [lazyLoad]="
                cdnPath +
                '/assets/images/content/img/cities/oceania/business-class-flights-perth.png'
              "
              alt="Perth"
              class="small"
          /></a>
          <div>
            <h2>Perth</h2>
            <p>Business Class</p>
            <a class="icon-link" [routerLink]="['/business-class/', 'perth']"
              >from <b>$2,048</b>
              <img
                [lazyLoad]="cdnPath + '/assets/images/content/icons/arrow_forward.svg'"
                alt="Arrow Icon"
                class="bi"
            /></a>
          </div>
        </div>
      </div>
    </div>
  </section>
</ng-template>
