<header>
  <div class="top-nav-info py-2">
    <!--        <img alt="AIG Travel Guard" [defaultImage]="cdnPath + '/assets/images/content/icons/aig-travel-guard-logo-default.png'"-->
    <!--             [lazyLoad]="cdnPath + '/assets/images/content/icons/aig-travel-guard-logo.png'"/>-->
    <img
      alt="AIG Travel Guard"
      [src]="cdnPath + '/assets/images/content/icons/aig-travel-guard-logo.png'" />
    <p>Protect your purchase with Trip Insurance from AIG Travel Guard!</p>
  </div>

  <nav #navbarWrapper id="navbar_top" class="navbar navbar-expand-xxl">
    <div class="container">
      <a class="navbar-brand" routerLink="/">
        <!--                <img width="140px" height="44" class="bi" [defaultImage]="cdnPath + '/assets/images/content/icons/empty-background.png'"-->
        <!--                     [lazyLoad]="cdnPath + '/assets/images/content/icons/logo.svg'" alt="cheapbusinessclasstickets.com">-->
        <img
          width="140px"
          height="44"
          class="bi"
          [src]="cdnPath + '/assets/images/content/icons/logo.svg'"
          alt="cheapbusinessclasstickets.com" />
      </a>

      <div class="text-end hide-d d-xxl-none">
        <a *ngIf="phoneVisible" href="tel:{{ phone }}" class="nav-phone">
          <img
            class="bi"
            [src]="cdnPath + '/assets/images/content/icons/call_talking.svg'"
            alt="Call Me Back" /><span>{{ phone | phone }}</span></a
        >
        <button
          type="button"
          (click)="openCallBackDialog()"
          class="btn call-me"
          data-bs-toggle="modal"
          data-bs-target="#callBack">
          Call Me
        </button>
        <button
          type="button"
          (click)="openGetQuoteDialog()"
          class="btn get-a-quote"
          data-bs-toggle="modal"
          data-bs-target="#freeQuotes">
          Get Quote
        </button>
      </div>

      <button
        (click)="$event.stopPropagation(); dealsDropdown.close(); collapse.toggle()"
        class="navbar-toggler"
        type="button"
        [attr.aria-expanded]="!isCollapsed"
        aria-controls="collapseExample"
        aria-label="navbar">
        <span class="navbar-toggler-icon"></span>
      </button>
      <div #collapse="ngbCollapse" [(ngbCollapse)]="isCollapsed" class="collapse navbar-collapse">
        <ul
          class="navbar-nav ms-auto col-12 col-lg-auto me-lg-auto mb-2 justify-content-center mb-md-0">
          <li (click)="closeNavigationAfterClick()" class="nav-item">
            <a class="nav-link" routerLink="/"> Home </a>
          </li>
          <li (click)="closeNavigationAfterClick()" class="nav-item">
            <a routerLink="/pricing" class="nav-link"> Pricing </a>
          </li>
          <li #dealsDropdown="ngbDropdown" ngbDropdown class="nav-item dropdown">
            <a
              ngbDropdownToggle
              (click)="$event.preventDefault()"
              class="nav-link dropdown-toggle"
              href="#"
              id="navbarDropdown"
              role="button"
              data-bs-toggle="dropdown"
              aria-expanded="false">
              Deals
            </a>
            <ul ngbDropdownMenu class="dropdown-menu" aria-labelledby="navbarDropdown">
              <li>
                <a
                  (click)="closeNavigationAfterClick()"
                  class="dropdown-item"
                  [routerLink]="['business-class/', DealsListEnum.Europe]"
                  >Europe</a
                >
              </li>
              <li>
                <a
                  (click)="closeNavigationAfterClick()"
                  class="dropdown-item"
                  [routerLink]="['business-class/', DealsListEnum.Asia]"
                  >Asia</a
                >
              </li>
              <li>
                <a
                  (click)="closeNavigationAfterClick()"
                  class="dropdown-item"
                  [routerLink]="['business-class/', DealsListEnum.Africa]"
                  >Africa</a
                >
              </li>
              <li>
                <a
                  (click)="closeNavigationAfterClick()"
                  class="dropdown-item"
                  [routerLink]="['business-class/', DealsListEnum.MiddleEast]"
                  >Middle East</a
                >
              </li>
              <li>
                <a
                  (click)="closeNavigationAfterClick()"
                  class="dropdown-item"
                  [routerLink]="['business-class/', DealsListEnum.Oceania]"
                  >Oceania</a
                >
              </li>
            </ul>
          </li>
          <li class="nav-item">
            <a (click)="closeNavigationAfterClick()" class="nav-link" routerLink="/about">
              About
            </a>
          </li>
          <li class="nav-item">
            <a (click)="closeNavigationAfterClick()" class="nav-link" routerLink="/contact">
              Contact
            </a>
          </li>
        </ul>
        <div class="text-end d-lg-none d-xl-none d-xxl-block">
          <a *ngIf="phoneVisible" href="tel:{{ phone }}" class="nav-phone">
            <!--                        <img class="bi" [lazyLoad]="cdnPath + '/assets/images/content/icons/call_talking.svg'" alt="Call Me Back"><span>{{phone | phone}}</span>-->
            <img
              class="bi"
              [src]="cdnPath + '/assets/images/content/icons/call_talking.svg'"
              alt="Call Me Back" /><span>{{ phone | phone }}</span>
          </a>
          <button
            (click)="openCallBackDialog()"
            type="button"
            class="btn call-me"
            data-bs-toggle="modal"
            data-bs-target="#callBack">
            Call Me
          </button>
          <button
            (click)="openGetQuoteDialog()"
            type="button"
            class="btn get-a-quote"
            data-bs-toggle="modal"
            data-bs-target="#freeQuotes">
            Get Quote
          </button>
        </div>
      </div>
    </div>
  </nav>
</header>
