import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import isEmpty from 'lodash-es/isEmpty';

import { environment } from '../../../../environments/environment';
import { SetFlightsToSearchBoxInterface } from '../../../model/interfaces/set-flights-to-searchbox.interface';
import { LocalstorageService } from '../../../services/local-storage.service';
import { DataService } from '../../../services/shared-data.service';
import { ComponentBase } from '../../common/ComponentBase';

@Component({
  selector: 'app-get-quote-dialog',
  templateUrl: './get-quote-dialog.component.html',
  styleUrls: ['./get-quote-dialog.component.scss'],
})
export class GetQuoteDialogComponent extends ComponentBase implements OnInit {
  @Input() public setFlightsToModal: SetFlightsToSearchBoxInterface;
  @Input() public flightPrice: number = 2140;
  public cdnPath: string;

  constructor(
    public activeModal: NgbActiveModal,
    private appStorage: LocalstorageService,
    private localStorageService: LocalstorageService,
    private dataService: DataService,
  ) {
    super();
    this.cdnPath = environment.cdnPath;
  }

  public ngOnInit(): void {
    const currentDestination: string = this.localStorageService.getItem('currentDestination');
    if (
      !isEmpty(currentDestination) &&
      isEmpty(this.setFlightsToModal?.flightFrom) &&
      isEmpty(this.setFlightsToModal?.flightTo)
    ) {
      this.setFlightsToModal = { flightTo: currentDestination, flightFrom: null };
    }
  }

  public closeDialog(): void {
    this.activeModal.dismiss();
  }

  public checkFlightCity(): string {
    if (this.setFlightsToModal?.flightTo?.length) {
      const index: number = this.setFlightsToModal.flightTo.indexOf('(');
      if (index >= 0) {
        return `to ${this.setFlightsToModal.flightTo.slice(0, index).trim()}`;
      }
      return null;
    }
    if (!isEmpty(this.appStorage.getItem('currentCityCountry'))) {
      return `to ${this.splitAndCapitalize(this.appStorage.getItem('currentCityCountry'))}`;
    }
    return null;
  }

  public ngOnDestroy(): void {
    this.dataService.sendData({ type: 'showModal', data: false });
  }
}
