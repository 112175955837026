import { Flight } from './flight';

export class QueryFlightParams {
  returnDate: any;
  departureDate: any;
  cabin: any;
  cabinStore: any;
  passengers: number | null;
  passengersStore: number | null;
  tripType: any;
  flights: Flight[];

  constructor(cabin?: any, passengers?: number, tripType?: any) {
    this.cabin = cabin || null;
    this.passengers = passengers || null;
    this.tripType = tripType || null;
  }
}
