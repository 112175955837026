import { isPlatformBrowser } from '@angular/common';
import { Component, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import * as _ from 'lodash';
import { CookieService } from 'ngx-cookie-service';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

import { NewsletterFormService } from './newsletter-form.service';
import { environment } from '../../../../environments/environment';
import { phoneMask, phonePattern } from '../../../app.constants';
import { ContactData } from '../../../model/contact-data';
import { CallMeService } from '../../../services/call-me.service';
import { ToastService } from '../../../services/toast.service';
import { NewsLetterSuccessDialogComponent } from '../../modal/news-letter-success-dialog/news-letter-success-dialog.component';

@Component({
  selector: 'app-news-letter',
  templateUrl: './news-letter.component.html',
  styleUrls: ['./news-letter.component.scss'],
  providers: [CallMeService, NewsletterFormService],
})
export class NewsLetterComponent implements OnInit {
  public phoneMask: (string | RegExp)[] = phoneMask;
  public callBackForm: FormGroup;
  public email: string;
  public hotDealsForm: FormGroup;
  public formValid: boolean;
  public formSent: boolean;
  public formSending: boolean = false;
  public phoneFormSending: boolean = false;
  public alreadySignedUp: boolean;
  public staticAlertClosed: boolean = false;
  public successMessage: string;
  public submittedPhoneForm: boolean = false;
  public cdnPath: string;

  private _success: Subject<string> = new Subject<string>();

  constructor(
    private callMeService: CallMeService,
    private signUpService: NewsletterFormService,
    private cookieService: CookieService,
    private formBuilder: FormBuilder,
    private toastService: ToastService,
    public dialog: MatDialog,
    @Inject(PLATFORM_ID) private platformId: Object,
  ) {
    this.cdnPath = environment.cdnPath;
  }

  ngOnInit() {
    this.initFormGroup();
    this.formValid = true;
    this.formSent = false;
    this.alreadySignedUp = false;
    this.hotDealsForm = this.formBuilder.group({
      email: ['', Validators.compose([Validators.required, Validators.email])],
    });
    if (isPlatformBrowser(this.platformId)) {
      if (!this.isEmpty(this.cookieService.get('WF_HOT_DEALS_SENT'))) {
        this.alreadySignedUp = true;
      }
      setTimeout(() => (this.staticAlertClosed = true), 20000);
    }

    this._success.subscribe(message => (this.successMessage = message));
    this._success.pipe(debounceTime(5000)).subscribe(() => (this.successMessage = null));
  }

  public initFormGroup(): void {
    this.callBackForm = this.formBuilder.group({
      phone: new FormControl<string>(
        '',
        Validators.compose([Validators.required, Validators.pattern(phonePattern)]),
      ),
    });
  }

  public callMe(): void {
    this.submittedPhoneForm = true;
    if (this.callBackForm.valid) {
      this.phoneFormSending = true;
      const contactData: ContactData = new ContactData();
      contactData.phone = this.callBackForm.get('phone').value;
      this.callMeService.callMe(contactData).subscribe({
        next: response => {
          this.phoneFormSending = false;
          if (_.eq(response.status, 'FAILED')) {
            this.toastService.showWarning(
              "Can't process your request now. Please try later.",
              10000,
            );
          } else {
            this.openSuccessDialog(true);
            this.callBackForm.reset();
            this.submittedPhoneForm = false;
          }
        },
        error: (): void => {
          this.phoneFormSending = false;
          this.toastService.showWarning("Can't process your request now. Please try later.", 10000);
        },
      });
    }
  }

  public sendHotDeal(): void {
    this.formValid = this.hotDealsForm.valid;
    if (this.formValid) {
      this.formSending = true;

      this.signUpService.sendEmail(this.email).subscribe({
        next: response => {
          this.formSending = false;
          if (!_.isNil(response.data.error)) {
            this.alertMessage(response.data.error);
          } else {
            this.cookieService.set('WF_HOT_DEALS_SENT', this.email);
            this.formSent = true;
            this.openSuccessDialog();

            this.alreadySignedUp = true;
          }
        },
        error: error => {
          this.alertMessage("Can't process your request now. Please try later.");
          this.formSending = false;
        },
      });
    }
  }

  private isEmpty(fieldValue): boolean {
    return _.isEmpty(fieldValue) || _.isUndefined(fieldValue) || _.isNull(fieldValue);
  }

  private alertMessage(message): void {
    this._success.next(message);
  }

  private openSuccessDialog(isCallBack: boolean = false): void {
    this.dialog.open(NewsLetterSuccessDialogComponent, { data: { isCallBack } });
  }
}
