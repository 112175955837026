import { formatCurrency, isPlatformBrowser } from '@angular/common';
import {
  AfterViewInit,
  Component,
  ElementRef,
  HostListener,
  Inject,
  Input,
  LOCALE_ID,
  OnDestroy,
  OnInit,
  PLATFORM_ID,
  ViewChild,
} from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormControl,
  FormGroup,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { makeStateKey, StateKey, TransferState } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import {
  NgbActiveModal,
  NgbDateParserFormatter,
  NgbDateStruct,
  NgbModal,
  NgbModalRef,
} from '@ng-bootstrap/ng-bootstrap';
import * as _ from 'lodash';
import { CookieService } from 'ngx-cookie-service';
import { interval, Subscription, switchMap } from 'rxjs';
import { debounceTime, map, takeWhile } from 'rxjs/operators';

import { SearchboxService } from './searchbox.service';
import { environment } from '../../../../environments/environment';
import { formatDate, isEmptyDate, prepareDate } from '../../../../shared/util/date-util';
import {
  fitCountrySelect,
  getElementById,
  getElementsByClass,
} from '../../../../shared/util/DOM-util';
import { AppStorage } from '../../../../storage/universal.inject';
import { phonePattern, phoneMask } from '../../../app.constants';
import { ContactData } from '../../../model/contact-data';
import { DealEntitiesEnum } from '../../../model/enums/deal-entities.enum';
import { Flight } from '../../../model/flight';
import { SetFlightsToSearchBoxInterface } from '../../../model/interfaces/set-flights-to-searchbox.interface';
import { NgbDateCustomParserFormatter } from '../../../model/ngb-date-custom-parser-formatter';
import { QueryFlightParams } from '../../../model/query-flight-params';
import { LocalstorageService } from '../../../services/local-storage.service';
import { DataService } from '../../../services/shared-data.service';
import { ToastService } from '../../../services/toast.service';
import { TrackingService } from '../../../services/tracking.service';
import { GetQuoteDialogComponent } from '../../modal/get-quote-dialog/get-quote-dialog.component';

const PHONE_KEY: StateKey<void> = makeStateKey('phone');
const PHONE_VISIBLE_KEY: StateKey<void> = makeStateKey('phoneVisible');

@Component({
  selector: 'app-searchbox',
  templateUrl: './searchbox.component.html',
  styleUrls: ['./searchbox.component.scss'],
  providers: [
    SearchboxService,
    {
      provide: NgbDateParserFormatter,
      useClass: NgbDateCustomParserFormatter,
    },
  ],
})
export class SearchboxComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input() public getOnlyForm: boolean;
  @Input() public activeModal: NgbActiveModal;
  @Input() public setFlightsToCountry: SetFlightsToSearchBoxInterface;
  @Input() public typeDealPage: DealEntitiesEnum;
  @Input() public set searchBoxCityQuotes(value: string) {
    this.textQuotes = value;
    setTimeout(() => {
      this.elRef.nativeElement
        .querySelectorAll('#hero .form-price tr')
        .forEach((item: HTMLElement): void => {
          const saleItem: HTMLElement = item.querySelector('.total-price s');
          const getCurrentPrice: number = Number(
            item.querySelector('.btn.get-a-quote').innerHTML.slice(1).replace(',', ''),
          );
          const discountPrice: number = this.randomIntFromInterval(50, 400);
          saleItem.innerHTML = formatCurrency(
            discountPrice + getCurrentPrice,
            this.locale,
            '$',
            'USD',
            '1.0-0',
          );
        });
    });
  }

  @ViewChild('phoneSelect', { static: false }) phoneSelect;

  public cdnPath: string;
  public textQuotes: string;
  queryPanelForm: FormGroup;
  roundTripForm: FormGroup;
  oneWayForm: FormGroup;
  multiCityForm: FormGroup;
  activeForm: FormGroup;

  /* Flight params */
  tripContent: boolean;
  cabinContent: boolean;
  tripTypeContent: boolean;
  passengersContent: boolean;
  queryFlightParams = new QueryFlightParams();
  flight: Flight = new Flight();
  flight1: Flight = new Flight();
  flight2: Flight = new Flight();
  returnDate: NgbDateStruct;

  /* Datepicker variables */
  maxDate: NgbDateStruct;
  minDateDepart: NgbDateStruct;
  minDateReturn: NgbDateStruct;
  minDateDepart1: NgbDateStruct;
  minDateDepart2: NgbDateStruct;
  firstDayOfWeek: number;
  displayMonths: number;
  navigation: string;

  normalFlight: boolean;
  submitDisabled: boolean;
  submitBtnText: string;
  activeDepart: boolean;
  activeDepart1: boolean;
  activeDepart2: boolean;
  activeReturn: boolean;

  /* Autocomplete data */
  filteredFromData;
  filteredFromOneWayData;
  filteredFromMultiCityData;
  filteredToData;
  filteredToOneWayData;
  filteredToMultiCityData;
  filteredFrom1MultiCityData;
  filteredTo1MultiCityData;
  filteredFrom2MultiCityData;
  filteredTo2MultiCityData;
  locationData: any[] = [];

  searchFormValid: boolean;
  queryPanelValid: boolean;
  formSending = false;
  contactData: ContactData = new ContactData();
  phoneMask = phoneMask;

  showAddFlightButton = true;
  showThirdFlight = false;

  focusOut = false;
  mustSubmit = false;
  phoneVisible = false;
  subscription: Subscription;
  phone: string;
  initFlightDataNow: boolean;
  lastFlight = new QueryFlightParams();
  clickTripType = true;
  contactPhoneModel: string;
  flightSubmitted = false;
  protected readonly Number = Number;
  public readonly DealEntitiesEnum = DealEntitiesEnum;

  constructor(
    private formBuilder: FormBuilder,
    private searchboxService: SearchboxService,
    private cookieService: CookieService,
    private route: ActivatedRoute,
    @Inject(PLATFORM_ID) private platformId,
    private router: Router,
    private dataService: DataService,
    private state: TransferState,
    @Inject(AppStorage) private appStorage: Storage,
    private trackingService: TrackingService,
    private modalService: NgbModal,
    private elRef: ElementRef,
    @Inject(LOCALE_ID) private locale: string,
    private localStorageService: LocalstorageService,
    private toastService: ToastService,
  ) {
    this.cdnPath = environment.cdnPath;
  }

  ngOnInit(): void {
    this.initPhone();
    this.normalFlight = true;
    this.searchFormValid = true;
    this.queryPanelValid = true;
    this.initTripContent();
    this.queryFlightParams.tripType = 'Round';
    this.submitBtnText = 'Get Quote';
    this.initForms();
    this.initFields();
    this.initAutocompleteForAll();
  }

  get landingClass() {
    return _.isNil(this.router) ||
      _.isNil(this.router.url) ||
      !this.router.url.includes('first-class')
      ? 'Business'
      : 'First';
  }

  changeMustSubmit(mustSubmit: boolean) {
    this.mustSubmit = mustSubmit;
  }

  initFields() {
    this.searchboxService.getInitQueryFlightParams().subscribe({
      next: (response): void => {
        this.queryFlightParams = response;
        if (_.eq(response.cabin, 'BLANK')) {
          this.queryFlightParams.cabin = 'BUSINESS';
        }
        this.flight.from = response.flights[0].from;
        this.flight.to = response.flights[0].to;
        this.flight.departDate = prepareDate(response.flights[0].departDate);
        this.returnDate = prepareDate(response.returnDate);
        this.minDateReturn = prepareDate(response.flights[0].departDate, 1, 'd');
        if (response.flights.length > 1) {
          this.flight1.from = response.flights[1].from;
          this.flight1.to = response.flights[1].to;
          this.flight1.departDate = prepareDate(response.flights[1].departDate);
          this.minDateDepart1 = prepareDate(response.flights[0].departDate);
        }
        if (response.flights.length === 3) {
          this.flight2.from = response.flights[2].from;
          this.flight2.to = response.flights[2].to;
          this.flight2.departDate = prepareDate(response.flights[2].departDate);
          this.minDateDepart2 = prepareDate(response.flights[1].departDate);
          this.showThirdFlight = true;
          this.showAddFlightButton = false;
        }
        if (this.setFlightsToCountry?.flightTo || this.setFlightsToCountry?.flightFrom) {
          this.flight.from = this.setFlightsToCountry.flightFrom
            ? this.setFlightsToCountry.flightFrom
            : this.flight.from;
          this.flight.to = this.setFlightsToCountry.flightTo;
        }
        this.setActiveForm(response.tripType);
        this.initFlightDataNow = true;
        if (isPlatformBrowser(this.platformId)) {
          this.initDatepicker();
        }
      },
      error: (): void => {
        if (isPlatformBrowser(this.platformId)) {
          this.initDatepicker();
        }
      },
    });
  }

  submitSearch() {
    if (this.activeForm === this.multiCityForm && !this.thirdFlightValid()) {
      return;
    }
    if (this.activeForm.valid && isPlatformBrowser(this.platformId)) {
      this.submit();
    }
  }

  submit(): void {
    this.fillQueryFlightParams();
    if (
      !_.isUndefined(this.queryFlightParams.cabin) &&
      !_.isUndefined(this.queryFlightParams.passengers)
    ) {
      if (this.modelChanged()) {
        this.formSending = true;
        this.disableButton(true);
        this.trackingService.getTrack().subscribe({
          next: (): void => {
            this.searchboxService.submitSearch(this.queryFlightParams).subscribe({
              next: response => {
                this.formSending = false;
                this.disableButton(false);
                if (!_.isNil(response.data.error)) {
                  alert(response.data.error);
                } else {
                  // We lost dial code after contact form hid, to prevent it store code before (WSF-1766)
                  if (this.normalFlight === true && response.data.normal === false) {
                    this.contactData.cCode = this.dialCode;
                  }
                  this.normalFlight = response.data.normal;
                  // restore dial code (WSF-1766)
                  setTimeout(() => {
                    if (this.phoneSelect) {
                      this.setCountry(this.contactData.cCode);
                    }
                  });
                  this.flightSubmitted = true;
                  this.lastFlight = _.clone(this.queryFlightParams);
                }
              },
              error: error => {
                this.formSending = false;
                this.disableButton(false);
              },
            });
          },
          error: (): void => {
            this.formSending = false;
            this.enableButton();
            this.showWarnNoty();
          },
        });
      }
    }
  }

  showWarnNoty(): void {
    this.toastService.showWarning("Can't process your request now. Please try later.", 10000);
  }

  private modelChanged() {
    return !_.isEqual(this.queryFlightParams, this.lastFlight);
  }

  private autoFillFromParam(name: string): void {
    if (_.isNil(name) || _.isEmpty(name)) {
      return;
    }

    this.searchboxService.getLocations(name.replace('-', ' ')).subscribe({
      next: (response: any[]): void => {
        if (response.length !== 0) {
          this.flight.to = response[0];
          this.localStorageService.setItem('currentDestination', response[0]);
        }
      },
      error: error => {
        //    do nothing
      },
    });
  }

  private initPhone() {
    if (!_.isNil(this.state.get(PHONE_KEY, null as any))) {
      this.phone = this.state.get(PHONE_KEY, null as any);
    }
    if (!_.isNil(this.state.get(PHONE_VISIBLE_KEY, null as any))) {
      this.phoneVisible = this.state.get(PHONE_VISIBLE_KEY, null as any);
    }
    this.subscription = this.dataService.getData().subscribe(sharedData => {
      if (_.eq(sharedData.type, 'phone')) {
        this.phone = sharedData.data;
      }
      if (_.eq(sharedData.type, 'phoneVisible')) {
        this.phoneVisible = sharedData.data;
      }
      if (_.eq(sharedData.type, 'autoFillTo')) {
        this.autoFillFromParam(sharedData.data);
      }
    });
  }

  private initTripContent() {
    this.queryFlightParams.cabin = 'BUSINESS';
    if (!_.isEmpty(this.appStorage.getItem('WF_CABIN_TYPE'))) {
      this.queryFlightParams.cabin = this.appStorage.getItem('WF_CABIN_TYPE');
    }
    this.queryFlightParams.passengers = 1;
    if (!_.isEmpty(this.appStorage.getItem('WF_ADULT'))) {
      this.queryFlightParams.passengers = Number(this.appStorage.getItem('WF_ADULT'));
    }
  }

  /* Start init autocomplete */
  private initAutocompleteForAll() {
    /* Init autocomplete for round trip */
    this.filteredFromData = this.initAutocomplete(this.roundTripForm, 'flyingFrom');
    this.filteredToData = this.initAutocomplete(this.roundTripForm, 'flyingTo');
    /* Init autocomplete for one way */
    this.filteredFromOneWayData = this.initAutocomplete(this.oneWayForm, 'flyingFrom');
    this.filteredToOneWayData = this.initAutocomplete(this.oneWayForm, 'flyingTo');
    /* Init autocomplete for multi city */
    this.filteredFromMultiCityData = this.initAutocomplete(this.multiCityForm, 'flyingFrom');
    this.filteredToMultiCityData = this.initAutocomplete(this.multiCityForm, 'flyingTo');
    this.filteredFrom1MultiCityData = this.initAutocomplete(this.multiCityForm, 'flyingFrom1');
    this.filteredTo1MultiCityData = this.initAutocomplete(this.multiCityForm, 'flyingTo1');
    this.filteredFrom2MultiCityData = this.initAutocomplete(this.multiCityForm, 'flyingFrom2');
    this.filteredTo2MultiCityData = this.initAutocomplete(this.multiCityForm, 'flyingTo2');
  }

  private initAutocomplete(form: FormGroup, contrl: string) {
    return (form.get(contrl) as FormGroup).valueChanges.pipe(
      debounceTime(200),
      switchMap(value => {
        if (!this.mustSubmit && _.isNil(value?.match(/\D+\(\w+\)/i)) && !_.isEmpty(value)) {
          return this.searchboxService.getLocations(value.trim()).pipe(
            map((response: any[]) => {
              if (response.length === 0 && !_.isNull(value) && !_.isEmpty(value)) {
                return Array.of('No cities found');
              }
              return response;
            }),
          );
        }
        return [];
      }),
    );
  }

  /* End init autocomplete */

  private initForms() {
    this.initRoundTripForm();
    this.initOneWayForm();
    this.initMultiCityForm();
    this.initQueryPanelForm();
    this.activeForm = this.roundTripForm;
  }

  private initRoundTripForm() {
    this.roundTripForm = this.formBuilder.group({
      flyingFrom: ['', [Validators.required, this.noWhitespaceValidator]],
      flyingTo: ['', [Validators.required, this.noWhitespaceValidator]],
      departDate: ['', Validators.required],
      returnDate: ['', Validators.required],
    });
    this.roundTripForm.valueChanges.pipe(debounceTime(100)).subscribe(() => {
      if (this.roundTripForm.valid && this.mustSubmit) {
        this.submitSearch();
      }
    });
  }

  private initOneWayForm() {
    this.oneWayForm = this.formBuilder.group({
      flyingFrom: ['', [Validators.required, this.noWhitespaceValidator]],
      flyingTo: ['', [Validators.required, this.noWhitespaceValidator]],
      departDate: ['', Validators.required],
    });
    this.oneWayForm.valueChanges.pipe(debounceTime(100)).subscribe(() => {
      if (this.oneWayForm.valid && this.mustSubmit) {
        this.submitSearch();
      }
    });
  }

  private initMultiCityForm() {
    this.multiCityForm = this.formBuilder.group({
      flyingFrom: ['', [Validators.required, this.noWhitespaceValidator]],
      flyingTo: ['', [Validators.required, this.noWhitespaceValidator]],
      flyingFrom1: ['', [Validators.required, this.noWhitespaceValidator]],
      flyingTo1: ['', [Validators.required, this.noWhitespaceValidator]],
      flyingFrom2: [''],
      flyingTo2: [''],
      departDate: ['', Validators.required],
      departDate1: ['', Validators.required],
      departDate2: [''],
    });
    this.multiCityForm.valueChanges.pipe(debounceTime(100)).subscribe(() => {
      if (this.multiCityForm.valid && this.thirdFlightValid() && this.mustSubmit) {
        this.submitSearch();
      }
    });
  }

  private thirdFlightValid() {
    return this.showThirdFlight
      ? this.showThirdFlight &&
          !_.isEmpty((this.multiCityForm.get('flyingFrom2') as FormControl).value) &&
          !_.isEmpty((this.multiCityForm.get('flyingTo2') as FormControl).value) &&
          !_.isEmpty((this.multiCityForm.get('departDate2') as FormControl).value)
      : true;
  }

  private initQueryPanelForm() {
    this.queryPanelForm = this.formBuilder.group({
      firstName: ['', Validators.compose([Validators.required, this.noWhitespaceValidator])],
      email: ['', Validators.compose([Validators.required, Validators.email])],
      phone: [
        '',
        Validators.compose([
          Validators.required,
          Validators.pattern(phonePattern),
          this.phoneValidator(),
        ]),
      ],
    });
  }

  private phoneValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (
        this.phoneSelect &&
        !_.isEmpty(control.value) &&
        control.value.startsWith('(1') &&
        this.phoneSelect.getCountryData().iso2 === 'us'
      ) {
        return { phoneFormatError: true };
      }
      return null;
    };
  }

  private initDatepicker(): void {
    this.firstDayOfWeek = 7;
    const width = window.innerWidth > 0 ? window.innerWidth : screen.width;
    this.displayMonths = width >= 768 ? 2 : 1;
    this.navigation = 'arrows';
    const now: Date = new Date();
    this.maxDate = prepareDate(now, 3, 'y');
    this.minDateDepart = prepareDate(now);
    if (isEmptyDate(this.flight.departDate)) {
      this.minDateReturn = prepareDate(now, 1, 'd');
      this.minDateDepart1 = prepareDate(now, 1, 'd');
      this.minDateDepart2 = prepareDate(now, 1, 'd');
    }
  }

  private setActiveForm(tripType: any) {
    if (_.eq(tripType, 'Round')) {
      this.activeForm = this.roundTripForm;
    } else if (_.eq(tripType, 'OneWay')) {
      this.activeForm = this.oneWayForm;
    } else {
      this.activeForm = this.multiCityForm;
    }
  }

  private enableButton() {
    this.submitDisabled = false;
    this.submitBtnText = 'Send Me a Quote Now';
  }

  private fillQueryFlightParams() {
    this.setFlights();
  }

  private setFlights() {
    // todo fix no-nested-ternary
    const flightCount: number =
      this.queryFlightParams.tripType === 'MultiCity'
        ? _.isUndefined(this.flight2.from) || _.isEmpty(this.flight2.from)
          ? 2
          : 3
        : 1;
    const flights: Flight[] = new Array(flightCount);
    this.setFromTo(flights, flightCount);
    this.setDepart(flights, flightCount);
    if (this.queryFlightParams.tripType === 'Round') {
      this.setReturnDate();
    }
    this.queryFlightParams.flights = flights;
  }

  private setFromTo(flights: Flight[], flightCount: number) {
    flights[0] = new Flight();
    flights[0].from = this.flight.from;
    flights[0].to = this.flight.to;
    if (flightCount >= 2) {
      flights[1] = new Flight();
      flights[1].from = this.flight1.from;
      flights[1].to = this.flight1.to;
    }
    if (flightCount === 3) {
      flights[2] = new Flight();
      flights[2].from = this.flight2.from;
      flights[2].to = this.flight2.to;
    }
  }

  private setDepart(flights: Flight[], flightCount: number) {
    flights[0].departDate = formatDate(this.flight.departDate);
    if (flightCount >= 2) {
      flights[1].departDate = formatDate(this.flight1.departDate);
    }
    if (flightCount === 3) {
      flights[2].departDate = formatDate(this.flight2.departDate);
    }
  }

  private setReturnDate() {
    this.queryFlightParams.returnDate = formatDate(this.returnDate);
  }

  private disableButton(disable: boolean) {
    this.submitDisabled = disable;
    this.submitBtnText = disable ? 'Loading...' : 'Get Quote';
  }

  setCountry(countryCode) {
    const currentCountry = _.filter(getElementsByClass('iti__country'), function (country) {
      return _.eq(countryCode, country.getAttribute('data-dial-code'));
    });
    if (currentCountry.length > 0) {
      this.phoneSelect.setCountry(currentCountry[0].getAttribute('data-country-code'));
    }
  }

  getCountryData() {
    return this.phoneSelect.getCountryData();
  }

  changeCabinType(cabinType: string) {
    this.queryFlightParams.cabin = cabinType;
    if (this.activeForm.valid) {
      this.submitSearch();
    }
  }

  changeTripType(tripType: string, currentForm) {
    this.queryFlightParams.tripType = tripType;
    this.activeForm = currentForm;
    this.focusOut = false;
    this.clickTripType = true;
  }

  changeTravelers() {
    if (this.activeForm.valid) {
      this.submitSearch();
    }
  }

  /* Close Datepicker */
  public onClickedOutsideDp(event, dpId, inputId): void {
    if (this.mustClose(event, inputId)) {
      if (_.includes(inputId, 'Depart1')) {
        this.activeDepart1 = false;
      } else if (_.includes(inputId, 'Depart2')) {
        this.activeDepart2 = false;
      } else if (_.includes(inputId, 'Depart')) {
        this.activeDepart = false;
      } else {
        this.activeReturn = false;
      }
      dpId.close();
    }
  }

  private mustClose(event, inputId) {
    let inDatepicker = false;
    _.each(event.target.classList, className => {
      if (_.includes(className, 'ngb') || _.eq(className, 'text-muted')) {
        inDatepicker = true;
      }
    });
    if (getElementById(inputId)) {
      return !(getElementById(inputId).contains(event.target) || inDatepicker);
    }
    return false;
  }

  getQuote() {
    if (this.queryPanelForm.get('phone').hasError('phoneFormatError')) {
      this.toastService.showWarning('Incorrect area code');
    }

    if (!this.activeForm.valid) {
      this.searchFormValid = false;
      this.queryPanelValid = false;
      return;
    }
    if (!this.flightSubmitted) {
      this.submitSearch();
      this.showWarnNoty();
      return;
    }
    if (this.queryPanelForm.valid) {
      this.formSending = true;
      this.disableButton(true);
      this.preparePhone();
      this.searchFormValid = true;
      this.queryPanelValid = true;
      this.trackingService.getTrack().subscribe({
        next: (): void => {
          this.searchboxService.sendRequest(this.contactData).subscribe({
            next: response => {
              this.formSending = false;
              this.disableButton(false);
              console.debug(`Get quote: ${response.data.details}`);
              if (_.eq(response.status, 'FAILED')) {
                alert(response.data.details);
              } else {
                /* Lead
                 Track when a user expresses interest in your offering (ex. form submission, sign up for trial, landing on pricing page) */
                // fbq('track', 'Lead');

                this.router.navigateByUrl('/thanks');
                if (this.getOnlyForm) {
                  this.activeModal.dismiss();
                }
              }
            },
            error: error => {
              console.warn("Can't process your request now. Please try later.");
              this.disableButton(false);
              this.formSending = false;
            },
          });
        },
        error: (): void => {
          this.formSending = false;
          this.disableButton(false);
          this.showWarnNoty();
        },
      });
    } else {
      this.queryPanelValid = false;
    }
  }

  private preparePhone() {
    this.contactData.cCode = this.dialCode;
    this.contactData.phone = _.replace(this.contactPhoneModel, new RegExp('[ ()-]', 'g'), '');
  }

  get dialCode(): string {
    const { dialCode } = this.phoneSelect.getCountryData();
    return !_.isNil(dialCode) && dialCode !== '0' ? dialCode : '1';
  }

  onClickedOutsideTripContent(event) {
    if (
      _.eq(event.target.className, 'BusinessClassClick') ||
      _.eq(event.target.className, 'traveler') ||
      _.eq(event.target.className, 'fa fa-angle-down')
    ) {
      return;
    }
    this.tripContent = false;
  }

  onClickedOutsideCabinType(event) {
    if (
      _.eq(event.target.className, 'cabinClick') ||
      _.eq(event.target.className, 'cabinClick ng-star-inserted') ||
      _.eq(event.target.className, 'cabinClick fa fa-angle-down') ||
      event.target.className.includes('cabinClick')
    ) {
      return;
    }
    this.cabinContent = false;
  }

  onClickedOutsideTripType(event) {
    if (
      _.eq(event.target.className, 'tripClick') ||
      _.eq(event.target.className, 'tripClick ng-star-inserted') ||
      _.eq(event.target.className, 'tripClick fa fa-angle-down') ||
      event.target.className.includes('tripClick')
    ) {
      return;
    }
    this.tripTypeContent = false;
  }

  public onClickedOutsidePassengers(event): void {
    if (
      _.eq(event.target.className, 'passengersClick') ||
      _.eq(event.target.className, 'passengersClick fa fa-angle-down') ||
      event.target.className.includes('passengersClick')
    ) {
      return;
    }
    this.passengersContent = false;
  }

  public initReturnDatepicker(): void {
    if (!isEmptyDate(this.flight.departDate)) {
      const date = new Date(
        this.flight.departDate.year,
        this.flight.departDate.month - 1,
        this.flight.departDate.day,
      );
      this.minDateReturn = prepareDate(date, 1, 'd');
      if (!this.initFlightDataNow && this.changedDepartDateModel()) {
        setTimeout(() => (this.activeReturn = true), 0);
      }
    }
  }

  changedDepartDateModel(): boolean {
    return _.isEmpty(this.lastFlight.flights)
      ? true
      : !_.eq(
          this.lastFlight.flights[0].departDate,
          formatDate((this.activeForm.get('departDate') as FormControl).value),
        );
  }

  public initDepartDatepicker(): void {
    if (!isEmptyDate(this.flight.departDate)) {
      const date = new Date(
        this.flight.departDate.year,
        this.flight.departDate.month - 1,
        this.flight.departDate.day,
      );
      this.minDateDepart1 = prepareDate(date);
    }
    if (!isEmptyDate(this.flight1.departDate)) {
      const date = new Date(
        this.flight1.departDate.year,
        this.flight1.departDate.month - 1,
        this.flight1.departDate.day,
      );
      this.minDateDepart2 = prepareDate(date);
    }
  }

  @HostListener('document:click')
  handleClick() {
    if (isPlatformBrowser(this.platformId)) {
      fitCountrySelect();
      this.initFlightDataNow = false;
      this.clickTripType = false;
    }
  }

  ngAfterViewInit(): void {
    if (isPlatformBrowser(this.platformId)) {
      setTimeout(() => {
        this.submitSearch();
        fitCountrySelect();
        /* Set contact info */
        this.searchboxService.getInitContactData().subscribe(response => {
          if (!_.isNull(response.data.contactInfo.email)) {
            this.contactData = response.data.contactInfo;
            const splittedPhone = _.split(response.data.contactInfo.phone, ' ');
            if (splittedPhone.length > 1) {
              this.contactPhoneModel = splittedPhone[1].replace(
                /(\d{3})(\d{3})(\d{4})/,
                '($1) $2-$3',
              );
              this.setCountry(_.replace(splittedPhone[0], '+', ''));
            }
          }
        });
      });
    }
  }

  addFlight(): void {
    this.showAddFlightButton = false;
    this.showThirdFlight = true;
  }

  ngOnDestroy(): void {
    // unsubscribe to ensure no memory leaks
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  onFocusOutAirportInput(event): void {
    if (
      _.isNil(event.relatedTarget) ||
      (!_.isNil(event.relatedTarget) && !_.includes(event.relatedTarget.className, 'mat-option'))
    ) {
      this.changeMustSubmit(true);
      if (this.formSending === false) {
        this.submitSearch();
      }
    }
  }

  public jumpToNextControl(element, autocompleteId?): void {
    if (
      _.eq(autocompleteId, 'flyingToRound') ||
      _.eq(autocompleteId, 'flyingToOneWay') ||
      _.eq(autocompleteId, 'flyingToMultiCity')
    ) {
      setTimeout(() => (this.activeDepart = true), 0);
    } else if (_.eq(autocompleteId, 'flyingTo1MultiCity')) {
      setTimeout(() => (this.activeDepart1 = true), 0);
    } else if (_.eq(autocompleteId, 'flyingTo2MultiCity')) {
      setTimeout(() => (this.activeDepart2 = true), 0);
    }
    this.changeMustSubmit(true);
    if (element && !this.initFlightDataNow && !this.clickTripType) {
      if (element instanceof HTMLInputElement) {
        this.setTimeOut(element);
      } else {
        const { nativeElement } = element._elRef;
        if (
          element &&
          (_.includes(nativeElement.id, 'Return') || _.includes(nativeElement.id, 'Depart'))
        ) {
          setTimeout(() => {
            const currentAutocomplete = getElementById(autocompleteId);
            if (currentAutocomplete) {
              currentAutocomplete.blur();
            }
            element.toggle();
          }, 100);
        }
      }
    }
  }

  setTimeOut(element) {
    setTimeout(() => {
      if (this.formSending) {
        interval(10)
          .pipe(takeWhile(() => this.formSending))
          .subscribe(() => {
            setTimeout(() => element.click(), 100);
            // This will be called every 10 milliseconds until `formSending` flag is set to false
          });
      } else {
        setTimeout(() => element.click(), 100);
      }
    }, 100);
  }

  capitalize(value: string) {
    return _.capitalize(value);
  }

  onBlur(id: string) {
    const blurredElement = getElementById(id);
    setTimeout(() => blurredElement.blur(), 0);
  }

  public noWhitespaceValidator(control: FormControl) {
    const isWhitespace = (control.value || '').trim().length === 0;
    const isValid = !isWhitespace;
    return isValid ? null : { isEmpty: true };
  }

  get withForm(): boolean {
    return (
      !this.router.url.includes('faq') &&
      !this.router.url.includes('about-us') &&
      !this.router.url.includes('terms-and-conditions') &&
      !this.router.url.includes('terms-and-conditions-app') &&
      !this.router.url.includes('insurance-terms') &&
      !this.router.url.includes('privacy-policy') &&
      !this.router.url.includes('corporate') &&
      !this.router.url.includes('reviews') &&
      !this.router.url.includes('our-team') &&
      !this.router.url.includes('your-request') &&
      !this.router.url.includes('privacy-app') &&
      !this.router.url.includes('contact-us') &&
      !this.router.url.includes('app-page')
    );
  }

  public makeAny(data): any {
    return data as any;
  }

  public openGetQuoteModal(event): void {
    event.preventDefault();
    const trElement: HTMLElement = event.target.closest('tr');
    if (trElement) {
      this.dataService.sendData({ type: 'showModal', data: true });
      const modalRef: NgbModalRef = this.modalService.open(GetQuoteDialogComponent, {
        fullscreen: true,
      });
      modalRef.componentInstance.setFlightsToModal = {
        flightFrom: trElement.getAttribute('flyingFrom'),
        flightTo: trElement.getAttribute('flyingTo'),
      };
      modalRef.componentInstance.flightPrice = trElement
        .querySelector('.btn.get-a-quote')
        .innerHTML.replace(/\D/g, '');
    }
  }

  public openGetQuoteModalWithoutParameters(): void {
    this.dataService.sendData({ type: 'showModal', data: true });
    this.modalService.open(GetQuoteDialogComponent, { fullscreen: true });
  }

  public countryChanged(): void {
    this.queryPanelForm.get('phone').updateValueAndValidity();
  }

  private randomIntFromInterval(min, max): number {
    return Math.floor(Math.random() * (max - min + 1) + min);
  }
}
