<section id="cta-info">
  <div class="container col-xl-12 px-2 py-5">
    <div class="row justify-content-center text-center me-0 ms-0">
      <h1 class="fw-bold lh-1 mb-3 py-2">Get Your Free Quote</h1>

      <div class="row col-md-12 col-lg-8 col-xl-6 px-2 py-5">
        <div class="col-6 col-xs-6 col-6 col-lg-6 br">
          <button
            (click)="openCallBackDialog()"
            class="btn call-me"
            data-bs-target="#callBack"
            data-bs-toggle="modal"
            type="button">
            Call Me
          </button>
        </div>

        <div class="col-6 col-xs-6 col-6 col-lg-6">
          <button
            (click)="openGetQuoteDialog()"
            class="btn get-a-quote"
            data-bs-target="#freeQuotes"
            data-bs-toggle="modal"
            type="button">
            Get Quote
          </button>
        </div>
      </div>
    </div>

    <hr class="featurette-divider" />

    <div class="row justify-content-center px-2 py-3 hiw-small me-0 ms-0">
      <div class="col-sm-4 ccol-lg-4 col-md-4 d-flex justify-content-center">
        <div class="icon-box icon-box-line">
          <div class="icon">
            <img
              [defaultImage]="cdnPath + '/assets/images/content/icons/square-background.webp'"
              [lazyLoad]="cdnPath + '/assets/images/content/icons/step_01.svg'"
              alt="01" />
          </div>
          <p>Request a Quote</p>
        </div>
      </div>

      <div class="col-sm-4 col-lg-4 col-md-4 d-flex justify-content-center">
        <div class="icon-box icon-box-line">
          <div class="icon">
            <img
              [defaultImage]="cdnPath + '/assets/images/content/icons/square-background.webp'"
              [lazyLoad]="cdnPath + '/assets/images/content/icons/step_02.svg'"
              alt="02" />
          </div>
          <p>Review Flight Options</p>
        </div>
      </div>

      <div class="col-sm-4 ccol-lg-4 col-md-4 d-flex justify-content-center">
        <div class="icon-box">
          <div class="icon">
            <img
              [defaultImage]="cdnPath + '/assets/images/content/icons/square-background.webp'"
              [lazyLoad]="cdnPath + '/assets/images/content/icons/step_03.svg'"
              alt="03" />
          </div>
          <p>Book & Save</p>
        </div>
      </div>
    </div>
  </div>
</section>
