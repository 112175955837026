<section id="hiw">
  <div class="container px-4 py-3">
    <div class="row align-items-center py-5">
      <h1 class="fw-bold lh-1">How It Works</h1>
    </div>

    <div class="row g-4 row-cols-1 row-cols-lg-3">
      <div class="feature col">
        <div class="feature-icon">
          <img
            [defaultImage]="cdnPath + '/assets/images/content/icons/square-background.webp'"
            [lazyLoad]="cdnPath + '/assets/images/content/icons/step_r_01.svg'"
            alt="01" />
        </div>
        <span>Get a free quote</span>
        <p>Complete the form above and tell us about your trip, it only takes few seconds.</p>
      </div>
      <div class="feature col">
        <div class="feature-icon">
          <img
            [defaultImage]="cdnPath + '/assets/images/content/icons/square-background.webp'"
            [lazyLoad]="cdnPath + '/assets/images/content/icons/step_r_02.svg'"
            alt="02" />
        </div>
        <span>Review flight options</span>
        <p>A friendly travel expert will quickly find the best itineraries and send them to you.</p>
      </div>
      <div class="feature col">
        <div class="feature-icon">
          <img
            [defaultImage]="cdnPath + '/assets/images/content/icons/square-background.webp'"
            [lazyLoad]="cdnPath + '/assets/images/content/icons/step_r_03.svg'"
            alt="03" />
        </div>
        <span>Book and save</span>
        <p>Pick your seats, book securely online, and save up to 20-50%.</p>
      </div>
    </div>
  </div>
</section>
