<div class="modal-content" id="freeQuotes">
  <div class="modal-header">
    <button
      (click)="closeDialog()"
      aria-label="Close"
      class="btn-close"
      data-bs-dismiss="modal"
      type="button"></button>
  </div>
  <div class="modal-body">
    <div class="container col-xl-12">
      <div class="row align-items-center g-lg-5 py-5">
        <div class="col-md-12 col-lg-5 col-xl-5 col-xxl-6 text-lg-start">
          <div class="flights-left-info">
            <h1 class="fw-bold">Up To 70% Off</h1>
            <h3 class="fw-bold py-2">
              Cheap Business Class Tickets {{ checkFlightCity() ?? null }}
            </h3>
            <div class="city-price">
              <small>From</small>
              <span>{{ flightPrice | currency: 'USD' : true : '1.0-0' }}</span>
            </div>
            <div class="city-price-hiw d-none d-lg-block">
              <small
                >Due to our contracts with airlines, we are unable to display the specifics of our
                discounted fares, such as itineraries and airline brands, on our website. Please
                fill out the request form, and a travel expert will contact you asap with the best
                flight and pricing options for your flight.</small
              >
              <h4>How It Works</h4>
              <span>1. Request a Quote</span>
              <span>2. Review Flight Options</span>
              <span>3. Book & Save</span>
            </div>
          </div>
        </div>

        <div class="col-md-12 mx-auto mt-0 col-lg-7 col-xl-7 col-xxl-6 px-0 px-md-4">
          <div class="row benefits p-3">
            <div class="col-4 col-lg-4 text-center">
              <img
                [lazyLoad]="cdnPath + '/assets/images/content/icons/Accredited.svg'"
                alt="Accredited business"
                class="bi" />
              <h2>Accredited business</h2>
              <p></p>
            </div>
            <div class="col-4 col-lg-4 text-center">
              <img
                [lazyLoad]="cdnPath + '/assets/images/content/icons/Trustpilot.svg'"
                alt="Trustpilot top rated"
                class="bi" />
              <h2>Trustpilot top rated</h2>
              <p></p>
            </div>
            <div class="col-4 col-lg-4 text-center">
              <img
                [lazyLoad]="cdnPath + '/assets/images/content/icons/Live.svg'"
                alt="Live concierge"
                class="bi" />
              <h2>Live concierge</h2>
              <p></p>
            </div>
          </div>

          <app-searchbox
            [activeModal]="activeModal"
            [getOnlyForm]="true"
            [setFlightsToCountry]="setFlightsToModal"></app-searchbox>

          <small class="d-block mt-2">We value your privacy </small>
        </div>

        <div
          class="row row-cols-3 row-cols-sm-3 row-cols-md-6 row-cols-lg-6 g-3 justify-content-around air-logos mt-5">
          <div class="col">
            <img [lazyLoad]="cdnPath + '/assets/images/content/logos/01.svg'" alt="Airfrance" />
          </div>
          <div class="col">
            <img [lazyLoad]="cdnPath + '/assets/images/content/logos/02.svg'" alt="Emirates" />
          </div>
          <div class="col">
            <img
              [lazyLoad]="cdnPath + '/assets/images/content/logos/03.svg'"
              alt="Cathay Pacific" />
          </div>

          <div class="col">
            <img
              [lazyLoad]="cdnPath + '/assets/images/content/logos/04.svg'"
              alt="British Airways" />
          </div>
          <div class="col">
            <img [lazyLoad]="cdnPath + '/assets/images/content/logos/05.svg'" alt="Lufthansa" />
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
