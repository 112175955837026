import { isPlatformBrowser } from '@angular/common';
import { AfterViewInit, Directive, ElementRef, Inject, PLATFORM_ID } from '@angular/core';
import { NgControl } from '@angular/forms';
import each from 'lodash-es/each';
import includes from 'lodash-es/includes';
import isEmpty from 'lodash-es/isEmpty';
import isNil from 'lodash-es/isNil';
import isNull from 'lodash-es/isNull';
import isUndefined from 'lodash-es/isUndefined';

@Directive({
  selector: '[appClearIcon]',
})
export class ClearIconDirective implements AfterViewInit {
  constructor(
    private elementRef: ElementRef,
    @Inject(PLATFORM_ID) private platformId: Object,
    private ngControl: NgControl,
  ) {}

  ngAfterViewInit() {
    if (isPlatformBrowser(this.platformId)) {
      const clearIcon = this.getClearIcon();
      if (isNil(clearIcon)) {
        console.warn('Clear icon not found');
      } else {
        clearIcon.addEventListener('click', this.onClick.bind(this));
        this.elementRef.nativeElement.addEventListener('focus', this.onFocus.bind(this));
        this.elementRef.nativeElement.addEventListener('focusout', this.onFocusout.bind(this));
      }
    }
  }

  onFocus() {
    const clearIcon = this.getClearIcon();
    this.setDisplay(clearIcon, 'inline');
  }

  onFocusout() {
    const clearIcon = this.getClearIcon();
    setTimeout(() => {
      this.setDisplay(clearIcon, 'none');
    }, 200);
  }

  onClick() {
    this.ngControl.control.setValue('');
    const clearIcon = this.getClearIcon();
    this.setDisplay(clearIcon, 'none');
  }

  private getClearIcon(): any {
    let clear;
    let { parentNode } = this.elementRef.nativeElement;
    if (includes(parentNode.classList, 'iti')) {
      parentNode = parentNode.parentNode;
    }
    each(parentNode.childNodes, (node: HTMLElement) => {
      if (
        !isNull(node.classList) &&
        !isUndefined(node.classList) &&
        !isEmpty(node.classList) &&
        node.classList.contains('xIcon')
      ) {
        clear = node;
      }
    });
    return clear;
  }

  private setDisplay(elem: any, value: string) {
    if (!(document.activeElement === this.elementRef.nativeElement && value === 'none')) {
      elem.style.display = value;
    }
  }
}
