import { isPlatformBrowser } from '@angular/common';
import {
  AfterViewInit,
  Directive,
  ElementRef,
  EventEmitter,
  HostListener,
  Inject,
  OnInit,
  Output,
  PLATFORM_ID,
} from '@angular/core';
import intlTelInput from 'intl-tel-input';
import $ from 'jquery';

@Directive({
  selector: '[ngxPhoneSelect]', // eslint-disable-line @angular-eslint/directive-selector
  exportAs: 'ngx-phone-select',
})
export class NgxPhoneSelectDirective implements AfterViewInit, OnInit {
  private el;
  private iti;
  private label: HTMLElement;
  private itiFlag: HTMLElement;

  @Output() countryChangeEvent: EventEmitter<void> = new EventEmitter<void>();

  @HostListener('ngModelChange') onNgModelChange() {
    if (this.label) {
      const isPlaceholderShown = this.el.nativeElement.matches(':placeholder-shown');
      if (!this.itiFlag)
        this.itiFlag = this.el.nativeElement.closest('.iti')?.querySelector('.iti__selected-flag');
      if (!isPlaceholderShown) {
        this.label.classList.add('phone-label');
        this.el.nativeElement.classList.add('phone-input');
        this.itiFlag.classList.add('active-iti');
      } else {
        this.label.classList.remove('phone-label');
        this.el.nativeElement.classList.remove('phone-input');
        this.itiFlag.classList.remove('active-iti');
      }
    }
  }

  constructor(
    el: ElementRef,
    @Inject(PLATFORM_ID) private platformId: Object,
  ) {
    this.el = el;

    $(this.el.nativeElement).on('countrychange', (e, countryData) => {
      this.countryChangeEvent.emit();
    });
  }

  public ngOnInit(): void {
    this.label = this.el.nativeElement.closest('.form-floating').querySelector('label');
    this.itiFlag = this.el.nativeElement.closest('.iti');
  }

  public ngAfterViewInit(): void {
    if (isPlatformBrowser(this.platformId)) {
      this.iti = intlTelInput(this.el.nativeElement);
      this.el.nativeElement
        .closest('.iti')
        ?.querySelector('.iti__selected-flag')
        .removeAttribute('aria-activedescendant');
    }
  }

  public setCountry(countryCode: any): void {
    this.iti.setCountry(countryCode.toLowerCase());
  }

  public getCountryData(): any {
    return this.iti.getSelectedCountryData();
  }
}
