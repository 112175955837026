<section id="pricing-hiw">
  <div class="container px-4 py-2">
    <div class="row g-4 py-3 row-cols-1 row-cols-lg-3">
      <div class="feature col">
        <div class="feature-icon">
          <img
            [defaultImage]="cdnPath + '/assets/images/content/icons/square-background.webp'"
            [lazyLoad]="cdnPath + '/assets/images/content/icons/Chat.svg'"
            alt="Chat" />
        </div>
        <span>What we do?</span>
        <p>
          We are a luxury travel agency specializing in cheap business class tickets. We’ll use our
          proven expertise and extensive resources to save you money.
        </p>
      </div>
      <div class="feature col">
        <div class="feature-icon">
          <img
            [defaultImage]="cdnPath + '/assets/images/content/icons/square-background.webp'"
            [lazyLoad]="cdnPath + '/assets/images/content/icons/Wallet.svg'"
            alt="Wallet" />
        </div>
        <span>How much does it cost?</span>
        <p>
          The service is fully transparent and absolutely free, there are no obligations or hidden
          fees! The price you are quoted is the price you pay!
        </p>
      </div>
      <div class="feature col">
        <div class="feature-icon">
          <img
            [defaultImage]="cdnPath + '/assets/images/content/icons/square-background.webp'"
            [lazyLoad]="cdnPath + '/assets/images/content/icons/Info_Square.svg'"
            alt="Info" />
        </div>
        <span>How we Do it?</span>
        <p>
          Our deeply discounted proprietary fares not available on online booking websites and are
          generally up to 20-50% cheap than retail prices.
        </p>
      </div>
    </div>
  </div>
</section>
