export const getElementById = (elementId: string): HTMLElement => {
  return <HTMLElement>document.getElementById(elementId);
};

export const getElementsByClass = (elementClass: string): HTMLCollectionOf<Element> => {
  return document.getElementsByClassName(elementClass);
};

export const fitCountrySelect = (): void => {
  const phoneElem: HTMLElement = <HTMLElement>getElementsByClass('iti')[0];
  if (phoneElem) {
    const width = phoneElem.offsetWidth;
    const countryListElem: HTMLElement = <HTMLElement>getElementsByClass('iti__country-list')[0];
    if (countryListElem) {
      countryListElem.style.width = `${width}px`;
    }
  }
};
