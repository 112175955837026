import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'app-contact-us-dialog',
  templateUrl: './contact-us-dialog.component.html',
  styleUrls: ['./contact-us-dialog.component.scss'],
})
export class ContactUsDialogComponent implements OnInit {
  public text: string;
  public success: boolean;
  public cdnPath: string;

  constructor(private dialogRef: MatDialogRef<ContactUsDialogComponent>) {}

  public ngOnInit(): void {
    this.cdnPath = environment.cdnPath;
  }

  public onCloseDialog(): void {
    this.dialogRef.close();
  }
}
