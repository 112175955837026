import { Component } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { environment } from '../../../../environments/environment';
import { DataService } from '../../../services/shared-data.service';
import { GetQuoteDialogComponent } from '../../modal/get-quote-dialog/get-quote-dialog.component';

@Component({
  selector: 'app-services',
  templateUrl: './services.component.html',
  styleUrls: ['./services.component.scss'],
})
export class ServicesComponent {
  public cdnPath: string;

  constructor(
    private modalService: NgbModal,
    private dataService: DataService,
  ) {
    this.cdnPath = environment.cdnPath;
  }

  public openGetQuoteDialog(): void {
    this.dataService.sendData({ type: 'showModal', data: true });
    this.modalService.open(GetQuoteDialogComponent, { fullscreen: true });
  }
}
