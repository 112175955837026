import { isPlatformBrowser } from '@angular/common';
import { ChangeDetectionStrategy, Component, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { NavigationStart, Router } from '@angular/router';
import eq from 'lodash-es/eq';
import isNil from 'lodash-es/isNil';
import * as moment from 'moment';
import { filter } from 'rxjs/operators';

import { environment } from '../../../../environments/environment';
import { DealsListEnum } from '../../../model/enums/deals-list.enum';
import { ComponentBase } from '../ComponentBase';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FooterComponent extends ComponentBase implements OnInit {
  public readonly DealsListEnum = DealsListEnum;
  public cdnPath: string;

  constructor(
    private router: Router,
    @Inject(PLATFORM_ID) private platformId: Object,
  ) {
    super();
  }

  fixBottom = false;
  private firstClassLandingDetected: boolean;

  ngOnInit() {
    this.cdnPath = environment.cdnPath;
    if (isPlatformBrowser(this.platformId)) {
      this.router.events
        .pipe(filter(event => event instanceof NavigationStart))
        .subscribe((url: any) => {
          this.fixBottom = eq(url, '/your-request');
        });
    }
  }

  get isBusinessLand(): boolean {
    const businessLanding = this.landingClass !== 'First';
    if (!this.firstClassLandingDetected) {
      this.firstClassLandingDetected = !businessLanding;
    }
    return businessLanding && !this.firstClassLandingDetected;
  }

  get landingClass() {
    return isNil(this.router) || isNil(this.router.url) || !this.router.url.includes('first-class')
      ? 'Business'
      : 'First';
  }

  get year(): string {
    return moment().format('YYYY'); // current year
  }
}
