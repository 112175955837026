import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { ContactData } from '../model/contact-data';
import { RestResponse } from '../model/rest-response';

@Injectable()
export class CallMeService {
  constructor(private http: HttpClient) {}

  public callMe(contactData: ContactData): Observable<RestResponse> {
    return this.http.post<RestResponse>(
      `${environment.serverUrl}/contact-info/callback`,
      contactData,
    );
  }
}
