import { isPlatformBrowser } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Inject, Injectable, Injector, PLATFORM_ID } from '@angular/core';
import * as express from 'express';
import isEmpty from 'lodash-es/isEmpty';
import { Observable, of, ReplaySubject } from 'rxjs';
import { ApplicationEventBroadcaster } from './application.event.broadcaster';
import { environment } from '../../environments/environment';
import { AppStorage } from '../../storage/universal.inject';

export class TrackingEvent {}

export class TrackingStartRequestEvent {}

export class TrackingFinishRequestEvent {}

@Injectable({
  providedIn: 'root',
})
export class TrackingService {
  trackInCookie = false;
  _current: Observable<any> | null;
  startTracking = false;

  constructor(
    private http: HttpClient,
    @Inject(AppStorage) private appStorage: Storage,
    @Inject(PLATFORM_ID) private platformId: string,
    private injector: Injector,
    private broadcaster: ApplicationEventBroadcaster,
  ) {
    this.broadcaster.onType(TrackingStartRequestEvent).subscribe(() => {
      this.startTracking = true;
    });
    this.broadcaster.onType(TrackingFinishRequestEvent).subscribe(() => {
      this.startTracking = false;
      this.clear();
    });
  }

  getTrack(): Observable<any> {
    console.debug('Track request...');
    this.trackInCookie = !!this.appStorage.getItem('WF_T');
    if (this.trackInCookie && !this.queryStringParams()) {
      console.debug('Track exist in cookie...');
      return of(this.appStorage.getItem('WF_T'));
    }
    if (this._current) {
      console.debug('Current track...');
      return this._current;
    }
    console.debug('New track...');
    return (this._current = cacheable<any>(this.http.get<any>(`${environment.serverUrl}/track`)));
  }

  private clear(): void {
    this._current = null;
  }

  private queryStringParams(): boolean {
    if (!isPlatformBrowser(this.platformId)) {
      const req: express.Request = this.injector.get('REQUEST');
      return !isEmpty(req.query);
    }
    return !!location.search;
  }
}

export function cacheable<T>(o: Observable<T>): Observable<T> {
  const replay = new ReplaySubject<T>(1);
  o.subscribe({
    next: x => replay.next(x),
    error: x => replay.error(x),
    complete: () => replay.complete(),
  });
  return replay.asObservable();
}
