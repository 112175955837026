import {
  Component,
  EventEmitter,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { UntilDestroy } from '@ngneat/until-destroy';

import { OfferService } from './offer.service';
import { environment } from '../../../../environments/environment';
import { DealEntitiesEnum } from '../../../model/enums/deal-entities.enum';
import { DealsListEnum } from '../../../model/enums/deals-list.enum';
import { RestResponse } from '../../../model/rest-response';
import { LocalstorageService } from '../../../services/local-storage.service';
import { DataService } from '../../../services/shared-data.service';

@UntilDestroy({ checkProperties: true })
@Component({
  selector: 'app-offer',
  templateUrl: './offer.component.html',
  styleUrls: ['./offer.component.scss'],
  providers: [OfferService],
})
export class OfferComponent implements OnInit, OnDestroy {
  @HostListener('window:beforeunload')
  private clearStorage(): void {
    this.ngOnDestroy();
  }

  @Input() public extendedListDeals: boolean = false;
  @Output() private typeDealPage: EventEmitter<DealEntitiesEnum> =
    new EventEmitter<DealEntitiesEnum>();

  @Output() private searchBoxCityQuotes: EventEmitter<string> = new EventEmitter<string>();
  public cdnPath: string;
  public isContinent: boolean = false;
  public isCountry: boolean = false;
  public isCity: boolean = false;
  public deal: string;
  public text: string;
  public readonly DealsListEnum = DealsListEnum;

  constructor(
    private route: ActivatedRoute,
    public router: Router,
    private offerService: OfferService,
    private appStorage: LocalstorageService,
    private dataService: DataService,
  ) {
    this.cdnPath = environment.cdnPath;
  }

  public ngOnInit(): void {
    if (this.extendedListDeals) {
      this.route.params.subscribe((res: Params): void => {
        this.deal = res['deals'];
        this.isContinent = Object.values(DealsListEnum).includes(res['deals'] as DealsListEnum);
        this.appStorage.removeItem('currentDestination');
        this.appStorage.removeItem('currentCityCountry');

        if (!this.isContinent) {
          this.offerService.getByName(this.deal).subscribe((response: RestResponse): void => {
            // localStorage.removeItem('currentCityCountry');
            this.typeDealPage.emit(response['customPageType']);
            if (response['customPageType'] === DealEntitiesEnum.Country) {
              this.appStorage.setItem('currentCityCountry', response['name']);
              // localStorage.setItem('currentCityCountry', response['name']);
              this.isCountry = true;
              this.text = response['content'];
            } else {
              this.isCountry = false;
            }

            if (response['customPageType'] === DealEntitiesEnum.City) {
              setTimeout(() => {
                this.dataService.sendData({ type: 'autoFillTo', data: response['name'] });
              }, 0);
              this.appStorage.setItem('currentCityCountry', response['name']);
              // localStorage.setItem('currentCityCountry', response['name']);
              this.isCity = true;
              const dividePartsIndex: number = response['content'].indexOf(
                '<section id="city-headers">',
              );
              this.text = response['content'].slice(dividePartsIndex);
              this.searchBoxCityQuotes.emit(response['content'].slice(0, dividePartsIndex));
            } else {
              this.isCity = false;
            }
          });
        } else {
          this.isCountry = false;
          this.isCity = false;
          this.typeDealPage.emit(DealEntitiesEnum.Continent);
        }
      });
    }
  }

  public ngOnDestroy(): void {
    // localStorage.removeItem('currentCityCountry');
    // if(!isEmpty(this.appStorage.getItem('currentCityCountry'))) {
    this.appStorage.removeItem('currentCityCountry');
    this.appStorage.removeItem('currentDestination');
    // }

    // localStorage.setItem('currentCityCountry', response['name']);
  }
}
