import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { NgxPhoneSelectDirective } from './ngx-phone-select';

@NgModule({
  declarations: [NgxPhoneSelectDirective],
  imports: [CommonModule],
  providers: [NgxPhoneSelectDirective],
  exports: [NgxPhoneSelectDirective],
})
export class NgxPhoneSelectModule {}
