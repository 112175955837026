import { ChangeDetectionStrategy, Component } from '@angular/core';

import { environment } from '../../../../environments/environment';

@Component({
  selector: 'app-book-with-us',
  templateUrl: './book-with-us.component.html',
  styleUrls: ['./book-with-us.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BookWithUsComponent {
  public cdnPath: string;

  constructor() {
    this.cdnPath = environment.cdnPath;
  }
}
