import { ChangeDetectionStrategy, Component } from '@angular/core';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'app-how-it-works',
  templateUrl: './how-it-works.component.html',
  styleUrls: ['./how-it-works.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HowItWorksComponent {
  public cdnPath: string;
  constructor() {
    this.cdnPath = environment.cdnPath;
  }
}
