<section id="faq">
  <div class="container col-xl-12 col-xxl-8 px-4 py-5">
    <div class="row align-items-center">
      <h1 class="fw-bold lh-1 mb-3">Frequently Asked Questions</h1>
    </div>

    <div ngbAccordion [closeOthers]="true" class="accordion-flush">
      <div ngbAccordionItem>
        <h2 ngbAccordionHeader>
          <button ngbAccordionButton type="button">How much will I save?</button>
        </h2>
        <div ngbAccordionCollapse>
          <div ngbAccordionBody>
            <ng-template
              >We can generally save up to 20-50% off retail prices and sometimes even
              more!</ng-template
            >
          </div>
        </div>
      </div>
      <div ngbAccordionItem>
        <h2 ngbAccordionHeader>
          <button ngbAccordionButton type="button">How quickly will I receive my quote?</button>
        </h2>
        <div ngbAccordionCollapse>
          <div ngbAccordionBody>
            <ng-template>
              We do out best to find discounted flight options ASAP. You can expect to receive a
              quote within 10 to 30 minutes.
            </ng-template>
          </div>
        </div>
      </div>
      <div ngbAccordionItem>
        <h2 ngbAccordionHeader>
          <button ngbAccordionButton type="button">Why should I use a travel expert?</button>
        </h2>
        <div ngbAccordionCollapse>
          <div ngbAccordionBody>
            <ng-template>
              A dedicated travel expert handles all aspects of your booking including seat
              assignments, meal preferences, travel insurance and even mileage accrual. Simply put,
              in order to get a commission your travel expert must save you money.
            </ng-template>
          </div>
        </div>
      </div>
      <div ngbAccordionItem>
        <h2 ngbAccordionHeader>
          <button ngbAccordionButton type="button">Why should I use your service?</button>
        </h2>
        <div ngbAccordionCollapse>
          <div ngbAccordionBody>
            <ng-template>
              Because you will save money and receive excellent customer service. Your personal
              travel expert will review your request and send you several discounted itineraries,
              answer questions, make adjustments and guide you through the whole process before,
              during and after your trip.
            </ng-template>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
