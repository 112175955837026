export class ComponentBase {
  static readonly WORD_SEPARATOR = '-';

  splitAndCapitalize(value: string): string {
    if (value.indexOf(ComponentBase.WORD_SEPARATOR)) {
      let name = '';
      const words = value.split(ComponentBase.WORD_SEPARATOR);
      for (let i = 0; i < words.length; i++) {
        name += this.capitalize(words[i]);
        name += ' ';
      }
      return name.trim();
    }
    return this.capitalize(value);
  }

  capitalize(value: string): string {
    return value.charAt(0).toUpperCase() + value.slice(1);
  }
}
