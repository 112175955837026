import { AfterViewInit, ChangeDetectionStrategy, Component, ElementRef } from '@angular/core';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'app-testimonials',
  templateUrl: './testimonials.component.html',
  styleUrls: ['./testimonials.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TestimonialsComponent implements AfterViewInit {
  public cdnPath: string;
  constructor(private elRef: ElementRef) {
    this.cdnPath = environment.cdnPath;
  }

  public ngAfterViewInit(): void {
    for (const item of this.elRef.nativeElement.querySelector('.carousel-indicators').children) {
      item.setAttribute('aria-label', 'Move to next slide');
    }
  }
}
