<section id="services">
  <div class="container col-xl-12 px-4 py-5">
    <div class="row featurette align-items-center">
      <div class="col-sm-12 col-md-12 col-lg-6 col-xl-5 text-lg-start">
        <p class="featurette-heading">Save up to 20-50% OFF <span>Business Class Tickets!</span></p>
        <p class="lead">
          Deep discounts on business class tickets with major airlines, including multiple airline
          combinations, complex routing, and upgrade vouchers.
        </p>
        <div class="col-auto">
          <button
            (click)="openGetQuoteDialog()"
            type="button"
            class="btn btn-primary mb-3"
            data-bs-toggle="modal"
            data-bs-target="#freeQuotes">
            Get a FREE Quote
          </button>
          <a class="btn btn-primary mb-3 bth-second" routerLink="/pricing">About Pricing</a>
        </div>
      </div>

      <div class="col-sm-12 col-md-12 col-xl-1 d-none d-xl-block d-xxl-block text-lg-start"></div>
      <div class="col-sm-12 col-md-12 col-lg-6">
        <img
          class="select-box__icon"
          [lazyLoad]="cdnPath + '/assets/images/content/img/services_001.png'"
          alt="Services"
          aria-hidden="true" />
      </div>
    </div>

    <div class="row featurette pt-5 align-items-center">
      <div class="col-sm-12 col-md-12 col-lg-6 col-xl-5 order-md-3 text-lg-start">
        <p class="featurette-heading">
          A Dedicated Concierge, <span>Never Wait On Hold Again!</span>
        </p>
        <p class="lead">
          A dedicated concierge does all the legwork including seat assignments, meal preferences,
          travel insurance and even mileage accrual. Simply put, we know how to save you money!
        </p>
        <div class="col-auto">
          <button
            (click)="openGetQuoteDialog()"
            type="button"
            class="btn btn-primary mb-3"
            data-bs-toggle="modal"
            data-bs-target="#freeQuotes">
            Get a FREE Quote
          </button>
          <a class="btn btn-primary mb-3 bth-second" routerLink="/about">About Us</a>
        </div>
      </div>
      <div class="col-sm-12 col-md-12 col-xl-1 d-none d-xl-block d-xxl-block order-md-2"></div>
      <div class="col-sm-12 col-md-12 col-lg-6 order-md-1 d-none d-sm-none d-md-none d-lg-block">
        <img
          class="select-box__icon"
          [lazyLoad]="cdnPath + '/assets/images/content/img/services_002.png'"
          alt="Services"
          aria-hidden="true" />
      </div>
    </div>
  </div>
</section>
