import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-city-deal',
  templateUrl: './city-deal.component.html',
  styleUrls: ['./city-deal.component.scss'],
})
export class CityDealComponent {
  @Input('content') text: string;

  constructor() {}
}
