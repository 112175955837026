import { isPlatformBrowser } from '@angular/common';
import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpParams,
  HttpRequest,
  HttpResponse,
} from '@angular/common/http';
import { Inject, PLATFORM_ID, Injectable } from '@angular/core';
import eq from 'lodash-es/eq';
import includes from 'lodash-es/includes';
import isEmpty from 'lodash-es/isEmpty';
import values from 'lodash-es/values';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { CustomHttpParameterCodec } from './custom-http-parameter-codec';
import { ApplicationEventBroadcaster } from '../services/application.event.broadcaster';
import { DataService } from '../services/shared-data.service';
import { ToastService } from '../services/toast.service';
import {
  TrackingFinishRequestEvent,
  TrackingStartRequestEvent,
} from '../services/tracking.service';
import { setTimeout$ } from '../services/utils';

@Injectable()
export class MainInterceptor implements HttpInterceptor {
  constructor(
    @Inject(PLATFORM_ID)
    private platformId: string,
    private dataService: DataService,
    private broadcaster: ApplicationEventBroadcaster,
    private toastService: ToastService,
  ) {
    this.dataService.getData().subscribe(sharedData => {
      if (eq(sharedData.type, 'updatesApplied')) {
        this.isUpdatesApplied = sharedData.data;
      }
      if (eq(sharedData.type, 'notyShowing')) {
        this.isNotyShowing = sharedData.data;
      }
    });
  }

  SHOW_TIMEOUT = 200;
  HIDE_TIMEOUT = 300;
  countRequest = 0;
  loaderVisible = false;
  showTimerId: any = null;
  hideTimerId: any = null;
  isUpdatesApplied: boolean;
  isNotyShowing: boolean;

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (this.needShowLoading(req.url)) {
      this.onStartRequest();
    }
    // Clone the request to add the new headers
    let clonedRequest;
    if (isPlatformBrowser(this.platformId)) {
      if (includes(req.url, '/track')) {
        this.startTracking(true);
      }
      const params = location.search
        .slice(1)
        .split('&')
        .map(p => p.split('='))
        .reduce((obj, pair) => {
          const [decodedKey, decodedValue] = pair.map(decodeURIComponent);
          let key = decodedKey;
          const value = decodedValue;
          /* Workaround that adblock not block requests with 'adgroupid' parameter with enabled SW */
          if (key === 'adgroupid') {
            key = 'adgroup';
          }
          return { ...obj, [key]: value };
        }, {});
      const originParams =
        (includes(req.url, '/track') ||
          includes(req.url, '/is-night') ||
          includes(req.url, '/get-search-params') ||
          includes(req.url, '/get-booking-params')) &&
        values(params).some(param => !isEmpty(param))
          ? new HttpParams({
              fromObject: params,
              encoder: new CustomHttpParameterCodec(),
            })
          : req.params;
      let headers = req.headers
        .append('X-Original-URL', location.href)
        .append('X-Site', 'www.cheapbusinessclasstickets.com');
      if (!isEmpty(document.referrer)) {
        headers = headers.append('X-Original-Referer', document.referrer);
      }
      clonedRequest = req.clone({
        withCredentials: true,
        params: originParams,
        headers,
      });
    } else {
      clonedRequest = req.clone({
        withCredentials: true,
      });
    }
    // Pass the cloned request instead of the original request to the next handle
    return next.handle(clonedRequest).pipe(
      tap(
        (event: HttpEvent<any>) => {
          if (event instanceof HttpResponse) {
            if (includes(event.url, '/track')) {
              this.startTracking(false);
            }
            if (this.needShowLoading(req.url)) {
              this.onStopRequest();
            }
          }
        },
        (err: any) => {
          if (err instanceof HttpErrorResponse) {
            if (includes(err.url, '/track')) {
              this.startTracking(false);
            }
            if (this.needShowLoading(req.url)) {
              this.onStopRequest();
            }
            if (this.needShowNoty(req.url)) {
              if (err.status === 500 || err.status === 400) {
                /* if (this.isUpdatesApplied == false) {
                  if (!this.isNotyShowing) {
                    this.notifyService.warnWithAvailableUpdates({
                      text: 'Can\'t process your request now. Our site was updated. We\'re strongly recommended to reload page. Otherwise we cannot guarantee stable work.'
                    });
                  }
                } else { */
                if (!this.isNotyShowing) {
                  this.toastService.showWarning(
                    "Can't process your request now. Please try later.",
                    10000,
                  );
                }
                /* } */
              } else if (
                err.status === 502 ||
                err.status === 503 ||
                err.status === 504 ||
                err.status === 599
              ) {
                if (!this.isNotyShowing) {
                  this.toastService.showWarning(
                    "Can't process your request now. Please try later.",
                    10000,
                  );
                }
              } else if (!this.isNotyShowing) {
                this.toastService.showWarning(
                  "Can't process your request now. Please try later.",
                  10000,
                );
              }
            }
          }
        },
      ),
    );
  }

  private needShowNoty(url: string) {
    return (
      includes(url, '/flight/query-flight') ||
      includes(url, '/contact-info/get-quote') ||
      includes(url, '/contact-info/corporate') ||
      includes(url, '/contact-info/personal') ||
      includes(url, '/contact-info/callback') ||
      includes(url, '/contact-info/contact-us') ||
      includes(url, '/reviews/save-review')
    );
  }

  private needShowLoading(url: string) {
    return (
      includes(url, '/users') ||
      includes(url, '/reviews/get-reviews-by-page') ||
      includes(url, '/unsubscribe')
    );
  }

  private onStartRequest() {
    this.countRequest++;
    if (this.hideTimerId) {
      clearTimeout(this.hideTimerId);
      this.hideTimerId = null;
    }
    if (!this.loaderVisible && !this.showTimerId) {
      this.showTimerId = setTimeout$(() => this.showLoader(true), this.SHOW_TIMEOUT);
    }
  }

  private onStopRequest() {
    this.countRequest--;
    if (this.countRequest <= 0) {
      this.countRequest = 0;
      if (this.showTimerId) {
        clearTimeout(this.showTimerId);
        this.showTimerId = null;
      }
      if (!this.hideTimerId) {
        this.hideTimerId = setTimeout$(() => this.showLoader(false), this.HIDE_TIMEOUT);
      }
    }
  }

  private showLoader(showLoader: boolean) {
    this.loaderVisible = showLoader;
    this.dataService.sendData({ type: 'showSpinner', data: showLoader });
  }

  private startTracking(start: boolean): void {
    if (start) {
      this.broadcaster.next(new TrackingStartRequestEvent());
    } else {
      this.broadcaster.next(new TrackingFinishRequestEvent());
    }
  }
}
