import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  HostListener,
  Inject,
  OnInit,
  ViewChild,
} from '@angular/core';
import { FormGroup } from '@angular/forms';
import { makeStateKey, StateKey, TransferState } from '@angular/platform-browser';
import { NavigationStart, Router } from '@angular/router';
import { NgbDropdown, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import * as _ from 'lodash';
import { interval } from 'rxjs';
import { filter, takeWhile } from 'rxjs/operators';

import { HeaderService } from './header.service';
import { environment } from '../../../../environments/environment';
import { cookieEnabled } from '../../../../shared/util/cookie-util';
import { AppStorage } from '../../../../storage/universal.inject';
import { DealsListEnum } from '../../../model/enums/deals-list.enum';
import { CallBackInterface } from '../../../model/interfaces/call-back.interface';
import { DataService } from '../../../services/shared-data.service';
import { TrackingService } from '../../../services/tracking.service';
import { CallMeDialogComponent } from '../../modal/call-me-dialog/call-me-dialog.component';
import { GetQuoteDialogComponent } from '../../modal/get-quote-dialog/get-quote-dialog.component';

const PHONE_KEY: StateKey<string> = makeStateKey<string>('phone');
const PHONE_VISIBLE_KEY: StateKey<string> = makeStateKey<string>('phoneVisible');

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  providers: [HeaderService],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HeaderComponent implements OnInit {
  @HostListener('window:scroll', ['$event'])
  private onScroll(): void {
    if (window.scrollY > 250) {
      this.navbarWrapper.nativeElement.classList.add('fixed-top');
      const navbarHeight = this.navbarWrapper.nativeElement.offsetHeight;
      document.body.style.paddingTop = `${navbarHeight}px`;
    } else {
      this.navbarWrapper.nativeElement.classList.remove('fixed-top');
      document.body.style.paddingTop = '0';
    }
  }

  @ViewChild('navbarWrapper') private navbarWrapper: ElementRef;
  @ViewChild('dealsDropdown') private dealsDropdown: NgbDropdown;

  public form: FormGroup;
  public isCollapsed: boolean = true;
  public readonly DealsListEnum = DealsListEnum;
  public cdnPath: string;
  public phoneVisible: boolean;
  public phone: string;
  public trackId: string | null;

  constructor(
    @Inject(AppStorage) private appStorage: Storage,
    private modalService: NgbModal,
    private headerService: HeaderService,
    private transferState: TransferState,
    private dataService: DataService,
    private trackingService: TrackingService,
    private router: Router,
  ) {}

  public ngOnInit(): void {
    this.router.events
      .pipe(filter(event => event instanceof NavigationStart))
      .subscribe((): void => {
        this.isCollapsed = true;
        if (this.dealsDropdown) {
          this.dealsDropdown.close();
        }
      });
    this.cdnPath = environment.cdnPath;
    this.trackingService.getTrack().subscribe(data => {
      this.setPhone();
      this.checkTrackCookie();
    });
  }

  private setPhone() {
    const phoneFromStorage = this.appStorage.getItem('WF_PHONE');
    if (phoneFromStorage) {
      this.phone = phoneFromStorage;
      this.sendPhoneData();
      this.transferState.set(PHONE_KEY, this.phone as any);
      this.setPhoneVisible();
    } else {
      this.headerService.getPhone().subscribe(response => {
        if (!_.isNil(response.data.phone)) {
          this.phone = response.data.phone;
          this.sendPhoneData();
          this.transferState.set(PHONE_KEY, this.phone as any);
          this.setPhoneVisible();
        }
      });
    }
  }

  public setPhoneVisible(): void {
    this.headerService.isNight().subscribe(response => {
      this.phoneVisible = !response.data.isNight;
      this.sendPhoneVisibleData();
      this.transferState.set(PHONE_VISIBLE_KEY, this.phoneVisible as any);
    });
  }

  public sendPhoneData(): void {
    this.dataService.sendData({ type: 'phone', data: this.phone });
  }

  public sendPhoneVisibleData(): void {
    this.dataService.sendData({ type: 'phoneVisible', data: this.phoneVisible });
  }

  public openCallBackDialog(): void {
    this.dataService.sendData({ type: 'showModal', data: true });
    this.modalService.open(CallMeDialogComponent, { centered: true, size: 'lg' });
  }

  public openGetQuoteDialog(): void {
    this.dataService.sendData({ type: 'showModal', data: true });
    this.modalService.open(GetQuoteDialogComponent, { fullscreen: true });
  }

  public closeNavigationAfterClick(): void {
    this.dealsDropdown.close();
    this.isCollapsed = true;
  }

  private checkTrackCookie() {
    interval(500)
      .pipe(
        takeWhile(() => {
          this.trackId = this.appStorage.getItem('WF_T');
          return !this.trackId && cookieEnabled();
        }),
      )
      .subscribe({
        next: () => console.debug(`TrackId = ${this.trackId}`),
        complete: () => this.dataService.sendData({ type: 'track', data: true }),
      });
  }
}
