import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { RestResponse } from '../../../model/rest-response';

@Injectable()
export class NewsletterFormService {
  constructor(
    private http: HttpClient,
    private router: Router,
  ) {}

  public sendEmail(email: string): Observable<RestResponse> {
    return this.http.post<RestResponse>(`${environment.serverUrl}/hot_deals_sign_up`, email);
  }
}
