import { Injectable } from '@angular/core';
import { NgbDateParserFormatter, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';

@Injectable()
export class NgbDateCustomParserFormatter extends NgbDateParserFormatter {
  parse(value: string): NgbDateStruct | null {
    if (value) {
      const dateParts = value.trim().split('/');
      if (dateParts.length === 1 && this.isNumber(dateParts[0])) {
        return { year: this.toInteger(dateParts[0]), month: 0, day: 0 };
      }
      if (dateParts.length === 2 && this.isNumber(dateParts[0]) && this.isNumber(dateParts[1])) {
        return { year: this.toInteger(dateParts[1]), month: this.toInteger(dateParts[0]), day: 0 };
      }
      if (
        dateParts.length === 3 &&
        this.isNumber(dateParts[0]) &&
        this.isNumber(dateParts[1]) &&
        this.isNumber(dateParts[2])
      ) {
        return {
          year: this.toInteger(dateParts[2]),
          month: this.toInteger(dateParts[1]),
          day: this.toInteger(dateParts[0]),
        };
      }
    }
    return null;
  }

  format(date: NgbDateStruct): string {
    const weekDays = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
    let stringDate = '';
    if (date) {
      const fullDate: Date = new Date(date.year, date.month - 1, date.day);
      stringDate += `${weekDays[fullDate.getDay()]} `;
      stringDate += this.isNumber(date.month) ? `${this.padNumber(date.month)}/` : '';
      stringDate += this.isNumber(date.day) ? this.padNumber(date.day) : '';
    }
    return stringDate;
  }

  padNumber(value: number) {
    if (this.isNumber(value)) {
      return `0${value}`.slice(-2);
    }
    return '';
  }

  isNumber(value: any): boolean {
    return !Number.isNaN(this.toInteger(value));
  }

  toInteger(value: any): number {
    return parseInt(`${value}`, 10);
  }
}
