<section id="wbwu">
  <div class="container px-4 py-5">
    <h1 class="fw-bold lh-1 mb-3">Why Book With Us?</h1>
    <div class="row g-4 py-5 row-cols-1 row-cols-sm-2 row-cols-lg-3">
      <div class="feature col">
        <div class="feature-icon">
          <img
            [lazyLoad]="cdnPath + '/assets/images/content/icons/checkmark_circle.svg'"
            alt="Checkmark" />
        </div>
        <span>Big Savings</span>
        <p>Discounts of up to 30-50% on first and business class tickets.</p>
      </div>
      <div class="feature col">
        <div class="feature-icon">
          <img
            [lazyLoad]="cdnPath + '/assets/images/content/icons/checkmark_circle.svg'"
            alt="Checkmark" />
        </div>
        <span>Premium Service</span>
        <p>Dedicated travel expert before and after your trip, never wait on hold.</p>
      </div>
      <div class="feature col">
        <div class="feature-icon">
          <img
            [lazyLoad]="cdnPath + '/assets/images/content/icons/checkmark_circle.svg'"
            alt="Checkmark" />
        </div>
        <span>Private Fares</span>
        <p>
          Access to privately negotiated and deeply discounted fares that are not available through
          booking websites.
        </p>
      </div>
      <div class="feature col">
        <div class="feature-icon">
          <img
            [lazyLoad]="cdnPath + '/assets/images/content/icons/checkmark_circle.svg'"
            alt="Checkmark" />
        </div>
        <span>Best Value</span>
        <p>
          We find the best deals through booking strategies, industry contacts and our proprietary
          deals.
        </p>
      </div>
      <div class="feature col">
        <div class="feature-icon">
          <img
            [lazyLoad]="cdnPath + '/assets/images/content/icons/checkmark_circle.svg'"
            alt="Checkmark" />
        </div>
        <span>Genuine Skill</span>
        <p>
          Our experts have extensive knowledge of flight routing, loyalty programs and airfare
          pricing.
        </p>
      </div>
      <div class="feature col">
        <div class="feature-icon">
          <img
            [lazyLoad]="cdnPath + '/assets/images/content/icons/checkmark_circle.svg'"
            alt="Checkmark" />
        </div>
        <span>Trust and Credibility</span>
        <p>
          In business since 2006 with thousands of excellent BBB ratings and TrustPilot reviews.
        </p>
      </div>
    </div>
  </div>
</section>
