import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { environment } from '../../../../environments/environment';
import { RestResponse } from '../../../model/rest-response';

@Injectable()
export class OfferService {
  constructor(private http: HttpClient) {}

  public getByName(name: string): Observable<RestResponse> {
    return this.http.get<RestResponse>(`${environment.serverUrl}/custom-page/get-by-name`, {
      params: {
        pageName: name,
        site: 'cbct',
      },
    });
  }
}
