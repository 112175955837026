import { isPlatformBrowser, LocationStrategy } from '@angular/common';
import { HttpClient, HttpParams } from '@angular/common/http';
import { AfterViewChecked, Component, Inject, OnDestroy, OnInit, PLATFORM_ID } from '@angular/core';
import { Meta } from '@angular/platform-browser';
import { NavigationEnd, NavigationStart, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import eq from 'lodash-es/eq';
import isEmpty from 'lodash-es/isEmpty';
import isNil from 'lodash-es/isNil';
import { CookieService } from 'ngx-cookie-service';
import { DeviceDetectorService } from 'ngx-device-detector';
import { BehaviorSubject, interval, Subscription } from 'rxjs';
import { filter, takeWhile } from 'rxjs/operators';
import { DataService } from './services/shared-data.service';
import { TagService } from './services/tag.service';
import { environment } from '../environments/environment';
import { cookieEnabled } from '../shared/util/cookie-util';

declare let ga: any;
declare global {
  // eslint-disable-next-line no-shadow
  interface Window {
    Trustpilot: any;
  }
}
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy, AfterViewChecked {
  cdnPath: string;
  title = 'app';
  phone: string;
  isPopState = false;
  showConversionCode: boolean;
  showSpinner: boolean;
  subscription: Subscription;
  clientId: string;
  trackExist: string;
  rendered = false;
  static isBrowser = new BehaviorSubject<boolean>(null);

  constructor(
    @Inject(PLATFORM_ID) private platformId: Object,
    private router: Router,
    private locStrat: LocationStrategy,
    /* private swUpdate: SwUpdate, */
    private dataService: DataService,
    private tagService: TagService,
    private http: HttpClient,
    private meta: Meta,
    private modalService: NgbModal,
    private cookieService: CookieService,
    private deviceService: DeviceDetectorService,
  ) {
    AppComponent.isBrowser.next(isPlatformBrowser(platformId));
  }

  ngOnInit() {
    this.meta.addTags([
      {
        property: 'og:image',
        content: '/assets/images/content/icons/logo.png',
      },
      {
        property: 'og:image:width',
        content: '230',
      },
      {
        property: 'og:image:height',
        content: '130',
      },
    ]);
    //
    this.cdnPath = environment.cdnPath;
    if (isPlatformBrowser(this.platformId)) {
      /* this.swUpdate.available.subscribe(event => {
        console.log('[App] Update available: current version is', event.current, 'available version is', event.available);
        this.notifyService.warnWithAvailableUpdates({
          text: 'Our site was updated. We\'re strongly recommended to reload page. Otherwise we cannot guarantee stable work.',
          layout: 'bottomRight',
          animation: {
            open: 'animated fadeInUp',
            close: 'animated fadeOutDown'
          }
        });
      }); */
      //
      //   window.document.addEventListener('scroll', this.scroll, true);
      //
      this.locStrat.onPopState(() => {
        this.isPopState = true;
      });
      //
      this.router.events.subscribe(event => {
        // Scroll to top if accessing a page, not via browser history stack
        if (event instanceof NavigationEnd && !this.isPopState) {
          window.scrollTo(0, 0);
          this.isPopState = false;
        }

        // Ensures that isPopState is reset
        if (event instanceof NavigationEnd) {
          this.isPopState = false;
        }
      });

      this.router.events
        .pipe(filter((event: any) => event instanceof NavigationStart))
        .subscribe((events: NavigationStart) => {
          this.showConversionCode = !eq(events.url, '/thanks'); // TODO: rewrite to 'your-request' path
        });

      this.subscription = this.dataService.getData().subscribe(sharedData => {
        if (eq(sharedData.type, 'showSpinner')) {
          this.showSpinner = sharedData.data;
        }
        if (eq(sharedData.type, 'track')) {
          this.trackExist = sharedData.data;
        }
        if (eq(sharedData.type, 'showModal')) {
          if (sharedData.data === true) {
            this.hideScroll();
          } else {
            this.showScroll();
          }
        }
      });
      this.checkCookie();

      interval(50000)
        .pipe(
          takeWhile(() => {
            // if (!isNil(ga)) {
            //   ga.getAll().forEach((tracker) => {
            //     this.clientId = tracker.get('clientId');
            //   });
            // }
            return !this.clientId && cookieEnabled();
          }),
        )
        .subscribe({
          next: (): void => console.warn(`ClientId = ${this.clientId}`),
          complete: (): void => this.saveClientId(),
        });

      interval(50000)
        .pipe(
          takeWhile(() => {
            return !this.trackExist && cookieEnabled();
          }),
        )
        .subscribe({
          next: (): void => console.warn(`trackExist = ${this.trackExist}`),
          complete: (): void => this.saveClientId(),
        });
    }
    // setTimeout$(() => { this.showExitModal(); }, 5000);
  }

  saveClientId() {
    if (!!this.clientId && this.trackExist) {
      this.http
        .get(`${environment.serverUrl}/save/client-id`, {
          params: new HttpParams().set('clientId', this.clientId),
        })
        .subscribe(() => {
          console.debug('Save clientId complete');
        });
    }
  }

  ngOnDestroy() {
    if (isPlatformBrowser(this.platformId)) {
      // window.document.removeEventListener('scroll', this.scroll, true);
      if (this.subscription) {
        this.subscription.unsubscribe();
      }
    }
  }

  ngAfterViewChecked() {
    if (window['dataLayer']) {
      window['dataLayer'].push({ event: 'optimize.activate' });
    }
  }

  // scroll = (): void => {
  //   const elements: HTMLCollectionOf<Element> = document.getElementsByClassName('navbar-expand');
  //   const header: Element = elements[0];
  //   const offsetTop = document.documentElement.scrollTop;
  //   if (offsetTop > 82) {
  //     header.classList.add('fixed-header-change-bg');
  //   } else {
  //     header.classList.remove('fixed-header-change-bg');
  //   }
  // }

  onMouseLeave($event) {
    if ($event.clientY > 15) {
      /* empty */
      // todo check method necessity
    }
  }

  checkCookie() {
    this.http
      .get(`${environment.serverUrl}/check-cookie`, {
        params: new HttpParams().set('cookieEnabled', cookieEnabled() ? 'true' : 'false'),
      })
      .subscribe(() => {
        console.debug('Check cookie complete');
      });
  }

  private hideScroll(): void {
    const scrollSize: number = window.innerWidth - document.body.scrollWidth;
    const block: HTMLElement = document.getElementById('navbar_top');
    if (block.classList.contains('fixed-top')) {
      block.style.width = `calc(100% - ${scrollSize}px)`;
    }
  }

  private showScroll(): void {
    const block: HTMLElement = document.getElementById('navbar_top');
    if (block.classList.contains('fixed-top')) {
      block.style.width = '100%';
      document.body.style.removeProperty('overflow');
      document.body.style.paddingRight = '0px';
    }
  }

  private isEmptyLocal(fieldValue): boolean {
    return isEmpty(fieldValue) || isNil(fieldValue);
  }

  // get  withForm(): boolean {
  //   const showForm = !this.router.url.includes('faq') &&
  //       !this.router.url.includes('about-us') &&
  //       !this.router.url.includes('terms-and-conditions') &&
  //       !this.router.url.includes('terms-and-conditions-app') &&
  //       !this.router.url.includes('insurance-terms') &&
  //       !this.router.url.includes('privacy-policy') &&
  //       !this.router.url.includes('corporate') &&
  //       !this.router.url.includes('reviews') &&
  //       !this.router.url.includes('our-team') &&
  //       !this.router.url.includes('your-request') &&
  //       !this.router.url.includes('privacy-app') &&
  //       !this.router.url.includes('contact-us') &&
  //       !this.router.url.includes('app-page') &&
  //       !this.router.url.includes('all-business-class');
  //
  //   return showForm;
  // }
  //
  // get showDefaultHeader(): boolean {
  //   return !this.router.url.includes('(s:b)') && !this.router.url.includes('(s:c)');
  // }
}
