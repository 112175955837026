<section id="testimonial">
  <div class="container col-xl-12 px-4 py-5 testimonial-inner">
    <img
      width="197"
      height="37"
      alt="Trustpilot"
      class="mb-4"
      [lazyLoad]="cdnPath + '/assets/images/content/icons/Trustpilot-stars.svg'" />
    <h1 class="fw-bold lh-1 mb-3">97% Of Travelers Recomend Us</h1>

    <ngb-carousel [showNavigationArrows]="false" [showNavigationIndicators]="true" class="py-5">
      <ng-template ngbSlide>
        <div class="container">
          <div class="text-start">
            <div class="row d-flex justify-content-center">
              <div class="col-sm-12 col-md-6 col-lg-4 px-2">
                <div class="tour-item">
                  <div class="tour-desc">
                    <div class="tour-text color-grey-3 text-center">
                      <span>“</span> An awesome experience <span>”</span>
                    </div>
                    <div class="link-name d-flex justify-content-center">
                      Booking with Blake was an awesome experience. He’s super knowledgeable and
                      well versed. And, he also saved me $800! Thank you Blake!
                    </div>
                    <img
                      alt="Trustpilot"
                      class="d-block m-auto mt-3 w-25"
                      [lazyLoad]="cdnPath + '/assets/images/content/icons/Trustpilot-stars.svg'" />
                    <div class="link-position justify-content-center">
                      <p>Devin Zimba</p>
                      <small>Los Angles, California</small>
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-sm-12 col-md-6 col-lg-4 px-2">
                <div class="tour-item">
                  <div class="tour-desc">
                    <div class="tour-text color-grey-3 text-center">
                      <span>“</span> A company you can trust <span>”</span>
                    </div>
                    <div class="link-name d-flex justify-content-center">
                      Mike has been booking my tickets for years, CBCT is a company you can trust.
                      They come though with great deal and save me money.
                    </div>
                    <img
                      alt="Trustpilot"
                      class="d-block m-auto mt-3 w-25"
                      [lazyLoad]="cdnPath + '/assets/images/content/icons/Trustpilot-stars.svg'" />
                    <div class="link-position justify-content-center">
                      <p>Roger Shaffer</p>
                      <small>Chicago, Illinois</small>
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-sm-12 col-md-6 col-lg-4 px-2 d-none d-sm-none d-md-none d-lg-block">
                <div class="tour-item">
                  <div class="tour-desc">
                    <div class="tour-text color-grey-3 text-center">
                      <span>“</span> I would highly recommend <span>”</span>
                    </div>
                    <div class="link-name d-flex justify-content-center">
                      I was very pleasantly surprised with Yana, by far the best booking experience
                      I’ve ever had. I would highly recommend booking your flights with Yana from
                      CBCT!
                    </div>
                    <img
                      alt="Trustpilot"
                      class="d-block m-auto mt-3 w-25"
                      [lazyLoad]="cdnPath + '/assets/images/content/icons/Trustpilot-stars.svg'" />
                    <div class="link-position justify-content-center">
                      <p>Mary Gonzalez</p>
                      <small>Seattle, Washington</small>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </ng-template>

      <ng-template ngbSlide>
        <div class="container">
          <div class="text-start">
            <div class="row d-flex justify-content-center">
              <div class="col-sm-12 col-md-6 col-lg-4 px-2">
                <div class="tour-item">
                  <div class="tour-desc">
                    <div class="tour-text color-grey-3 text-center">
                      <span>“</span> This company is legit <span>”</span>
                    </div>
                    <div class="link-name d-flex justify-content-center">
                      I was skeptical at first, but this company is legit. It worked just as the
                      website said, I submitted a request and Jeff called me back in a few minutes.
                    </div>
                    <img
                      class="d-block m-auto mt-3 w-25"
                      [lazyLoad]="cdnPath + '/assets/images/content/icons/Trustpilot-stars.svg'"
                      alt="Trustpilot" />
                    <div class="link-position justify-content-center">
                      <p>Mary Gonzalez</p>
                      <small>Seattle, Washington</small>
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-sm-12 col-md-6 col-lg-4 px-2 d-none d-sm-none d-md-none d-lg-block">
                <div class="tour-item">
                  <div class="tour-desc">
                    <div class="tour-text color-grey-3 text-center">
                      <span>“</span> Low price and great service <span>”</span>
                    </div>
                    <div class="link-name d-flex justify-content-center">
                      “Blake did a great job, he found a great route for a low price and provided
                      great service”
                    </div>
                    <img
                      class="d-block m-auto mt-3 w-25"
                      [lazyLoad]="cdnPath + '/assets/images/content/icons/Trustpilot-stars.svg'"
                      alt="Trustpilot" />
                    <div class="link-position justify-content-center">
                      <p>Sofi Hard</p>
                      <small>Chicago, Illinois</small>
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-sm-12 col-md-6 col-lg-4 px-2">
                <div class="tour-item">
                  <div class="tour-desc">
                    <div class="tour-text color-grey-3 text-center">
                      <span>“</span> Found me a cheap flight <span>”</span>
                    </div>
                    <div class="link-name d-flex justify-content-center">
                      Everything went smoothly and they found me a cheap flight just like they
                      promised. I would gladly recommend to anyone looking to book cheap tickets.
                    </div>
                    <img
                      class="d-block m-auto mt-3 w-25"
                      [lazyLoad]="cdnPath + '/assets/images/content/icons/Trustpilot-stars.svg'"
                      alt="Trustpilot" />
                    <div class="link-position justify-content-center">
                      <p>Eva Henderson</p>
                      <small>Miami, Florida</small>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </ng-template>

      <ng-template ngbSlide>
        <div class="container">
          <div class="text-start">
            <div class="row d-flex justify-content-center">
              <div class="col-sm-12 col-md-6 col-lg-4 px-2">
                <div class="tour-item">
                  <div class="tour-desc">
                    <div class="tour-text color-grey-3 text-center">
                      <span>“</span> BestExperience Ever <span>”</span>
                    </div>
                    <div class="link-name d-flex justify-content-center">
                      atient with my indecision and in the end found us the best way to reach our
                      off- the -beaten -path destination in France. She booked our Iberian trip in
                      2021 and she was so great that we dialed her number and never considered
                      shopping around.
                    </div>
                    <img
                      class="d-block m-auto mt-3 w-25"
                      [lazyLoad]="cdnPath + '/assets/images/content/icons/Trustpilot-stars.svg'"
                      alt="Trustpilot" />
                    <div class="link-position justify-content-center">
                      <p>Eva Henderson</p>
                      <small>Illinois, Aurora</small>
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-sm-12 col-md-6 col-lg-4 px-2">
                <div class="tour-item">
                  <div class="tour-desc">
                    <div class="tour-text color-grey-3 text-center">
                      <span>“</span> Exceeded My Expectations! <span>”</span>
                    </div>
                    <div class="link-name d-flex justify-content-center">
                      CBCT found airline seats for us that I would not have been able to locate
                      online. This is a fantastic service that saved us time and money. We are going
                      Business Class, upgraded from Premium Economy due to Ben's expertise in
                      working with a dynamic seat inventory and clients' slightly flexible schedule.
                      I wish that I had known about this company previously.
                    </div>
                    <img
                      class="d-block m-auto mt-3 w-25"
                      [lazyLoad]="cdnPath + '/assets/images/content/icons/Trustpilot-stars.svg'"
                      alt="Trustpilot" />
                    <div class="link-position justify-content-center">
                      <p>Mary Gonzalez</p>
                      <small>Nevada, Henderson</small>
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-sm-12 col-md-6 col-lg-4 px-2 d-none d-sm-none d-md-none d-lg-block">
                <div class="tour-item">
                  <div class="tour-desc">
                    <div class="tour-text color-grey-3 text-center">
                      <span>“</span> The Experience Ever <span>”</span>
                    </div>
                    <div class="link-name d-flex justify-content-center">
                      Tickets She is professional, yet friendly. Patient with my indecision and in
                      the end found us the best way to reach our off- the -beaten -path destination
                      in France. She booked our Iberian trip in 2021 and she was so great that we
                      dialed her number and never considered shopping around.
                    </div>
                    <img
                      class="d-block m-auto mt-3 w-25"
                      [lazyLoad]="cdnPath + '/assets/images/content/icons/Trustpilot-stars.svg'"
                      alt="Trustpilot" />
                    <div class="link-position justify-content-center">
                      <p>Michigan, Grand Rapids</p>
                      <small>Chicago, Illinois</small>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </ng-template>
    </ngb-carousel>
  </div>
</section>
