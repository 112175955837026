<footer class="footer mt-auto">
  <section class="successes">
    <div class="container px-4 py-2">
      <div class="row g-4 py-5 row-cols-1 row-cols-sm-2 row-cols-md-4 row-cols-lg-4">
        <div class="feature col">
          <span>35,000+</span>
          <p>happy customers</p>
        </div>
        <div class="feature col">
          <span>500,000+</span>
          <p>tickets sold</p>
        </div>
        <div class="feature col">
          <span>$687</span>
          <p>average discount</p>
        </div>
        <div class="feature col">
          <span>20+</span>
          <p>travel experts</p>
        </div>
      </div>

      <hr class="featurette-divider" />
    </div>
  </section>

  <section class="flights-b">
    <div class="container col-xl-12 col-xxl-8 px-4 py-5">
      <div class="row text-lg-start">
        <div class="col-sm-12 flights-list">
          <p>Trending countries</p>
          <ul>
            <li>
              <a [routerLink]="['business-class/', 'austria']"><span>Austria</span></a>
            </li>
            <li>
              <a [routerLink]="['business-class/', 'belgium']"><span>Belgium</span></a>
            </li>
            <li>
              <a [routerLink]="['business-class/', 'china']"><span>China</span></a>
            </li>
            <li>
              <a [routerLink]="['business-class/', 'cyprus']"><span>Cyprus</span></a>
            </li>
            <li>
              <a [routerLink]="['business-class/', 'france']"><span>France</span></a>
            </li>
            <li>
              <a [routerLink]="['business-class/', 'germany']"><span>Germany</span></a>
            </li>
            <li>
              <a [routerLink]="['business-class/', 'greece']"><span>Greece</span></a>
            </li>
            <li>
              <a [routerLink]="['business-class/', 'india']"><span>India</span></a>
            </li>
            <li>
              <a [routerLink]="['business-class/', 'italy']"><span>Italy</span></a>
            </li>
            <li>
              <a [routerLink]="['business-class/', 'japan']"><span>Japan</span></a>
            </li>
            <li>
              <a [routerLink]="['business-class/', 'netherlands']"><span>Netherlands</span></a>
            </li>
            <li>
              <a [routerLink]="['business-class/', 'norway']"><span>Norway</span></a>
            </li>
            <li>
              <a [routerLink]="['business-class/', 'spain']"><span>Spain</span></a>
            </li>
            <li>
              <a [routerLink]="['business-class/', 'sweden']"><span>Sweden</span></a>
            </li>
            <li>
              <a [routerLink]="['business-class/', 'thailand']"><span>Thailand</span></a>
            </li>
            <li>
              <a [routerLink]="['business-class/', 'united-kingdom']"
                ><span>United Kingdom</span></a
              >
            </li>
          </ul>
        </div>

        <div class="col-sm-12 flights-list">
          <p>Popular cities</p>
          <ul>
            <li>
              <a [routerLink]="['business-class/', 'amsterdam']"><span>Amsterdam</span></a>
            </li>
            <li>
              <a [routerLink]="['business-class/', 'bangkok']"><span>Bangkok</span></a>
            </li>
            <li>
              <a [routerLink]="['business-class/', 'barcelona']"><span>Barcelona</span></a>
            </li>
            <li>
              <a [routerLink]="['business-class/', 'hong-kong']"><span>Hong Kong</span></a>
            </li>
            <li>
              <a [routerLink]="['business-class/', 'london']"><span>London</span></a>
            </li>
            <li>
              <a [routerLink]="['business-class/', 'madrid']"><span>Madrid</span></a>
            </li>
            <li>
              <a [routerLink]="['business-class/', 'milan']"><span>Milan</span></a>
            </li>
            <li>
              <a [routerLink]="['business-class/', 'munich']"><span>Munich</span></a>
            </li>
            <li>
              <a [routerLink]="['business-class/', 'nice']"><span>Nice</span></a>
            </li>
            <li>
              <a [routerLink]="['business-class/', 'paris']"><span>Paris</span></a>
            </li>
            <li>
              <a [routerLink]="['business-class/', 'rome']"><span>Rome</span></a>
            </li>
            <li>
              <a [routerLink]="['business-class/', 'shanghai']"><span>Shanghai</span></a>
            </li>
            <li>
              <a [routerLink]="['business-class/', 'tokyo']"><span>Tokyo</span></a>
            </li>
            <li>
              <a [routerLink]="['business-class/', 'venice']"><span>Venice</span></a>
            </li>
            <li>
              <a [routerLink]="['business-class/', 'vienna']"><span>Vienna</span></a>
            </li>
            <li>
              <a [routerLink]="['business-class/', 'zurich']"><span>Zurich</span></a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </section>

  <div class="container icons__container py-3">
    <hr class="featurette-divider" />

    <a class="navbar-brand" routerLink="/">
      <img
        class="bi"
        width="100%"
        height="100%"
        [lazyLoad]="cdnPath + '/assets/images/content/icons/logo.svg'"
        alt="cheapbusinessclasstickets.com" />
    </a>

    <div class="footer-nav">
      <ul class="ms-auto col-12 col-lg-auto me-lg-auto mb-2 justify-content-center">
        <li class="nav-item"><a class="nav-link" routerLink="/"> Home </a></li>
        <li class="nav-item"><a class="nav-link" routerLink="/pricing"> Pricing </a></li>
        <li class="nav-item"><a class="nav-link" routerLink="/about"> About </a></li>
        <li class="nav-item"><a class="nav-link" routerLink="/contact"> Contact </a></li>
      </ul>

      <ul class="ms-auto col-12 col-lg-auto me-lg-auto mb-2 justify-content-center">
        <li class="nav-item">
          <a class="nav-link" [routerLink]="['business-class/', DealsListEnum.Europe]">Europe</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" [routerLink]="['business-class/', DealsListEnum.Asia]">Asia</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" [routerLink]="['business-class/', DealsListEnum.Africa]">Africa</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" [routerLink]="['business-class/', DealsListEnum.MiddleEast]"
            >Middle East</a
          >
        </li>
        <li class="nav-item">
          <a class="nav-link" [routerLink]="['business-class/', DealsListEnum.Oceania]">Oceania</a>
        </li>
      </ul>
    </div>

    <hr class="featurette-divider" />

    <div class="ms-auto col-12 col-lg-auto footer-icons">
      <div class="col-sm-12">
        <i class="geo-icon"></i>
        <i class="ssl-icon"></i>
        <i class="mastercard-icon"></i>
        <i class="visa-icon"></i>
        <i class="amex-icon"></i>
        <i class="discover-icon"></i>
        <i class="bbb-icon"></i>
        <i class="iatan-icon"></i>
      </div>
    </div>

    <hr class="featurette-divider" />

    <small>© 2023 Cheap Business Class Tickets. All right reserved.</small>
    <small
      >Use of this Website constitutes acceptance of the
      <a routerLink="/terms-of-service">Terms of Service</a> and
      <a routerLink="/privacy-policy">Privacy Policy</a>.</small
    >
  </div>
</footer>
