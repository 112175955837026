import { isPlatformServer } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class TagService {
  constructor(
    private router: Router,
    @Inject(PLATFORM_ID) private platformId,
  ) {
    if (isPlatformServer(this.platformId)) {
      return;
    }

    const { gtag } = <any>window;
    const { bing } = <any>window;

    // router.events.subscribe(event => {
    //   if (event instanceof NavigationEnd) {
    //     /* Google Analytics pageview event */
    //     gtag('config', 'UA-2039848-1', {'optimize_id': 'GTM-WXLBLTS',
    //       page_path: event.urlAfterRedirects
    //     });
    //     /* Google AdWords */
    //     gtag('config', 'AW-1061651272');
    //     console.debug('Visiting ' + event.urlAfterRedirects + ' should be tracked via GTag');
    //     /* Bing Ads */
    //     bing();
    //   }
    // });
  }
}
