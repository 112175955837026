import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { RestResponse } from '../../../model/rest-response';

@Injectable()
export class HeaderService {
  constructor(private http: HttpClient) {}

  isNight(): Observable<RestResponse> {
    return this.http.get<RestResponse>(`${environment.serverUrl}/contact-info/is-night`);
  }

  getPhone(): Observable<RestResponse> {
    return this.http.get<RestResponse>(`${environment.serverUrl}/contact-info/get-phone`);
  }
}
