<ngb-toast
  *ngFor="let toast of toastService.toasts"
  [autohide]="true"
  [delay]="toast.delay || 5000"
  (hidden)="toastService.remove(toast)"
  [class]="toast.classname"
  [animation]="true">
  {{ toast.body }}
  <button
    class="btn-close"
    [class.btn-close-white]="toast.classname === 'error'"
    (click)="toastService.remove(toast)"></button>
</ngb-toast>
